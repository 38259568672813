const nextQuestionsIdsToIdentifiers = (next_questions, questions) => {
    let pieces = next_questions.toString().replace(/\s/g, "").split(",")
    let newNextQuestions = ''
    if (next_questions.length !== 0) {
        let found = questions.filter(question => question.id.toString() === pieces[0])
        if (found.length === 0) {
            newNextQuestions += 'ID NOT FOUND'
        }
        else {
            newNextQuestions += found[0]?.identifier
        }
    }
    for (let j = 1; j < pieces.length; j++) {
        newNextQuestions += ', ' + questions.filter(question => question.id.toString() === pieces[j])[0]?.identifier
    }
    return newNextQuestions
}




const ruleIdsToIdentifiers = (rule, questions) => {
    // We go through the string and add all chars that are not numbers to 'newRule'.
    // When a char is a number, we know it must be part of an id code. We keep adding 
    // numbers to 'oldId' until we come across a char that is no longer a number.
    // When this happens, we go get the corresponding identifier for this id code.
    // If there is a ':' after the number, we know the number must have been the id
    // code of a question, otherwise it was the ID code of an answer choice.
    let qlength = questions.length
    let newRule = ''
    let oldID = ''

    // Loop through each character of the rule
    for (let k = 0; k < rule.length; k++) {
        // If the char is a number
        if (rule[k] >= '0' && rule[k] <= '9') {
            oldID += rule[k] // add the number to oldId

            // If the next char is ":", we know we now have the ID code of a question
            // in oldID
            if (k < rule.length - 1 && rule[k + 1] === ':') {
                let idToReplace = oldID
                let found_question = questions.filter(question => question.id.toString() === idToReplace)
                if (found_question.length === 0) {
                    newRule += 'QUESTION ID '+ oldID +' NOT FOUND'
                }
                else {
                    newRule += found_question[0]?.identifier
                }
                oldID = ''
            }

            // If the next char is not a number or ":", or there is no next char, we know
            // we have the id code of an answer choice in oldID.
            // In this case we need to go through all the questions to find the option (A, B, C...)
            // to display
            if (k === rule.length - 1 || (!(rule[k + 1] >= '0' && rule[k + 1] <= '9') && rule[k + 1] !== ':')) {
                let answerToReplace = oldID
                let found = false // we can stop searching through other questions the moment we find the answer
                let m = 0 //index of the question we are checking in the list of all questions
                if (questions.length === 0) {
                    found = true
                }

                // Start going through all the questions in the question list
                while (!found) {
                    let answers = questions[m].answers
                    let n = 0
                    // Go through all the answers of the question to see
                    // if we have found the answer choice we are looking for
                    while (n < answers.length && !found) {
                        if (answers[n].id.toString() === answerToReplace) {
                            newRule += answers[n].choice
                            oldID = ''
                            found = true //we have found the answer choice (A, B, C...). We can stop the search
                        }
                        n += 1
                    }
                    m += 1
                    // If there are no more questions in the list, set found=true to stop loop
                    if (m >= qlength && !found) {
                        found = true //the ID code was incorrect. Must stop or loop forever
                        newRule += 'ANSWER CHOICE '+oldID+' NOT FOUND'
                    }
                }
            }
        }
        // If the char is not a number, just add it to newRule
        else {
            newRule += rule[k]
            oldID = ''
        }
    }
    return newRule
}


const functions = {
    nextQuestionsIdsToIdentifiers,
    ruleIdsToIdentifiers
}

export default functions