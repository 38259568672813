/**
 * List stores item ids in the value attribute of button element. When an
 * svg icon is used in the button instead of text, event target can be the
 * button element, the svg element or a path inside the svg element. This
 * function returns correct id value for all of these cases.
 * 
 * @param {object} event Event object passed from List components buttons.
 * @returns List item id value.
 */
const getItemId = (event) => {
    const tagName = event.target.tagName
    if (tagName === 'BUTTON') return event.target.value
    if (tagName === 'svg') {
        return event.target.parentNode.getAttribute('value')
    }
    if (tagName === 'path') {
        return event.target.parentNode.parentNode.getAttribute('value')
    }
}

export default getItemId
