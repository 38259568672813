import { useState } from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import LinkEditor from './LinkEditor'
import List from '../List'
import linkService from '../../services/links'
import { 
    CATEGORY_MAX_LENGTH,
    LIST_HEADER_CHAR_ASCENDING,
    LIST_HEADER_CHAR_DESCENDING
} from '../../common/constants'
import ConfirmationDialog from '../ConfirmDialog'
import idChanger from '../../logic/IdsToIdentifiers'
import getListItemId from '../../common/getListItemId'

/**
 * Subview for editing feedback links on RDM Intro feedback view.
 * 
 * Links are hyperlinks to support material given as part of the feedback. Link
 * has a name and an url that it refers to. Links can also be given a category.
 * Subview renders an editor form for creating and editing links
 * (<i>LinkEditor</i>) and a list of links that are already in the database
 * (generated using <i>List</i>). To make the rendering of the list
 * possible the component must be given an array of links freshly fetched
 * from the database as a prop. View also needs to refresh these lists and this
 * is done using <i>refreshBlocks()</i> function that comes from the
 * <i>SubView</i> component of the <i>FeedbackView</i>. Props
 * <i>toLinkSubView</i> and <i>toLinkSubView</i> are functions also coming from
 * the SubView component. They are passed to <i>BlockEditor</i> and used there
 * for switching subviews from links in the editor form.
 * 
 * An array of question objects fetched freshly from the database is also
 * needed, so that the identifiers used by the user in the rules can be changed
 * into valid database ids. Display of alerts is implemented in the
 * <i>SubView</i> component of the <i>FeedbackView</i> and it is used through
 * the functions in the <i>showAlert</i> and <i>showDismissibleAlert</i> props.
 */
const LinkSubView = ({
    links,
    setLinks,
    refreshLinks,
    showAlert,
    showDismissibleAlert,
    categories,
    setLinkOrderBy,
    questions
}) => {
    // -- Subview state -------------------------------------------------------

    const newLinkHeading = 'New link'
    const newLinkSubmitText = 'Create'
    const editLinkHeading = 'Edit link'
    const editLinkSubmitText = 'Save'
    const [linkCategory, setLinkCategory] = useState('')
    const [linkCategoryInputValue, setLinkCategoryInputValue] = useState('')
    const [linkRule, setLinkRule] = useState('')
    const [linkText, setLinkText] = useState('')
    const [linkUrl, setLinkUrl] = useState('')
    const [linkId, setLinkId] = useState(0)
    const [linkEditorHeading, setLinkEditorHeading] = useState(
        newLinkHeading
    )
    const [linkEditorSubmitText, setLinkEditorSubmitText] = useState(
        newLinkSubmitText
    )
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
    const [confirmDialogText, setConfirmDialogText] = useState('')
    const [linkToDelete, setLinkToDelete] = useState(null)
    const [orderAscending, setOrderAscending] = useState(false)
    const [header, setHeader] = useState(['Category', 'Text'])

    // -- Handler functions ---------------------------------------------------

    const handleLinkCategoryChange = (selectedCategoryOption) => {
        setLinkCategory(selectedCategoryOption)
    }

    const handleLinkCategoryInputChange = (inputValue) => {
        const value = (inputValue.length <= CATEGORY_MAX_LENGTH)
            ? inputValue
            : inputValue.substr(0, CATEGORY_MAX_LENGTH)
        setLinkCategoryInputValue(value)
    }

    const handleLinkRuleChange = (event) => {
        setLinkRule(event.target.value)
    }

    const handleLinkTextChange = (event) => {
        setLinkText(event.target.value)
    }

    const handleLinkUrlChange = (event) => {
        setLinkUrl(event.target.value)
    }

    const handleLinkSave = () => {
        const errorMessageCreate = (
            <><strong>ERROR:</strong> Failed to create link.</>
        )
        const errorMessageUpdate = (
            <><strong>ERROR:</strong> Failed to update link.</>
        )
        const successMessageCreate = (
            <><strong>SUCCESS:</strong> New link created successfully.</>
        )
        const successMessageUpdate = (
            <><strong>SUCCESS:</strong> Link updated successfully.</>
        )
        const badRequestMessageCreate = (
            <><strong>ERROR:</strong> Failed to create link. Link has to have a category.</>
        )

        // Default value for linkId is 0 and the ids in the db start from 1.
        // So, if linkId is now 0 we know this is a new db entry and we'll leave
        // the id field out of the object and use the create function. Otherwise we
        // update the entry in which case we must also include the id in the object.
        if (linkId !== 0) {
            const linkObj = {
                id: linkId,
                rule: linkRule,
                text: linkText,
                url: linkUrl,
                category: linkCategory.label
            }
            linkService
                .updateLink(linkId, linkObj)
                .then(response => {
                    handleLinkFormClear()
                    refreshLinks()
                    showAlert(successMessageUpdate, 'success')
                })
                .catch(error => {
                    refreshLinks()
                    showDismissibleAlert(errorMessageUpdate, 'danger')
                })
        } else {
            const linkObj = {
                text: linkText,
                rule: linkRule,
                url: linkUrl,
                category: linkCategory.label
            }
            linkService
                .createLink(linkObj)
                .then(response => {
                    handleLinkFormClear()
                    refreshLinks()
                    showAlert(successMessageCreate, 'success')
                })
                .catch(error => {
                    if (error.code === 'ERR_BAD_REQUEST') {
                        showDismissibleAlert(badRequestMessageCreate, 'danger')
                    }
                    else {
                        showDismissibleAlert(errorMessageCreate, 'danger')
                    }
                    refreshLinks()
                })
        }

        // update object in links array or add a new link object with temporary data
        if (linkId !== 0) {
            const newLinks = links.slice()
            const index = links.findIndex(obj => obj.id === linkId)
            newLinks[index].category.id=linkCategory.value
            newLinks[index].category.category_name=linkCategory.label
            newLinks[index].text=linkText
            newLinks[index].rule=linkRule
            newLinks[index].url=linkUrl
            newLinks[index].id=linkId
            setLinks(newLinks)
        }
        else {
            setLinks(links.concat(
                {id: 'saving',
                text: 'saving...',
                category: {id: 'saving...', category_name: 'saving...'}
            }))
        }
    }

    const handleLinkFormClear = () => {
        setLinkEditorHeading(newLinkHeading)
        setLinkEditorSubmitText(newLinkSubmitText)
        setLinkCategory('')
        setLinkCategoryInputValue('')
        setLinkRule('')
        setLinkText('')
        setLinkUrl('')
        setLinkId(0)
    }

    const handleLinkEdit = (event) => {
        const itemId = getListItemId(event)
        const link = links.find(l => l.id.toString() === itemId)

        refreshLinks()

        setLinkEditorHeading(editLinkHeading + ' ' + link.id)
        setLinkEditorSubmitText(editLinkSubmitText)
        setLinkCategory({
            value: link.category ? link.category.id : 'null',
            label: link.category ? link.category.category_name : 'null'
        })
        setLinkRule(idChanger.ruleIdsToIdentifiers(link.rule, questions))
        setLinkText(link.text)
        setLinkUrl(link.url)
        setLinkId(link.id)
    }

    const handleLinkDelete = (event) => {
        const itemId = getListItemId(event)
        updateConfirmDialog(
            'Are you sure you want to delete this link?',
            true,
            itemId
        )
    }

    // -- Delete confirmation dialog ------------------------------------------

    const updateConfirmDialog = (text, visible, lnkId) => {
        setConfirmDialogText(text)
        setConfirmDialogVisible(visible)
        setLinkToDelete(lnkId)
    }

    const handleDeleteConfirmation = (confirmed) => {
        if (confirmed) {
            deleteLink()
            updateConfirmDialog('', false, null)
        }
        updateConfirmDialog('', false, null)
    }

    const deleteLink = () => {
        const lnkId = linkToDelete

        linkService
            .deleteLink(lnkId)
            .then(response => {
                // If the link we removed is in the editor, we must set it's id to
                // default value 0 and change linkEditorHeading.
                if (lnkId === linkId.toString()) {
                    setLinkId(0)
                    setLinkEditorHeading(newLinkHeading)
                }
                refreshLinks()
            })
            .catch(error => {
                refreshLinks()
            })
    }

    // -- Link list sorting ---------------------------------------------------

    // Removes wedge characters indicating the direction of the sort from the
    // end of the string text.
    const clearHeaderField = (text, charAsc, charDesc) => {
        const lastChar = text.slice(-1)
        if (lastChar === charAsc || lastChar === charDesc) {
            return text.slice(0, text.length-2)
        }
        return text
    }

    const handleLinkListSort = (event) => {
        const charAsc = LIST_HEADER_CHAR_ASCENDING
        const charDesc = LIST_HEADER_CHAR_DESCENDING

        const headerIndex = Number(event.target.getAttribute('value'))
        const field = header[headerIndex]
        const lastChar = field.slice(-1)
        const fieldBase = (lastChar === charAsc || lastChar === charDesc )
            ? field.slice(0, field.length-2)
            : field
        const newOrder = (orderAscending)
            ? fieldBase.toLowerCase()
            : '-' + fieldBase.toLowerCase()
        setLinkOrderBy(newOrder)

        const newDirectionChar = (orderAscending) ? charAsc : charDesc
        const newHeader = header.map(
            text => clearHeaderField(text, charAsc, charDesc)
        )
        newHeader[headerIndex] = fieldBase + ' ' + newDirectionChar
        setHeader(newHeader)
        
        if (orderAscending) {
            setOrderAscending(false)
        } else {
            setOrderAscending(true)
        }
    }

    // -- Prepare props for subcomponents -------------------------------------

    // Category array must be mapped to the format that is accepted by
    // react-select as options.
    const categoryOptions = categories.map(category => ({
        value: category.id,
        label: category.category_name
    }))

    // Props for List component
    const cats = links.map(
        link => link.category ? link.category.category_name : '-'
    )
    const names = links.map(link => link.text)
    const ids = links.map(link => link.id)

    // -- Return JSX ----------------------------------------------------------

    return (
        <Container>
            <Row>
                <Col lg={8} className="mb-3">
                    <LinkEditor
                        linkCategory={linkCategory}
                        linkCategoryInputValue={linkCategoryInputValue}
                        linkCategoryOptions={categoryOptions}
                        linkRule={linkRule}
                        linkText={linkText}
                        linkUrl={linkUrl}
                        linkEditorHeading={linkEditorHeading}
                        linkEditorSubmitText={linkEditorSubmitText}
                        handleLinkCategoryChange={handleLinkCategoryChange}
                        handleLinkCategoryInputChange={
                            handleLinkCategoryInputChange
                        }
                        handleLinkRuleChange={handleLinkRuleChange}
                        handleLinkTextChange={handleLinkTextChange}
                        handleLinkUrlChange={handleLinkUrlChange}
                        handleLinkSave={handleLinkSave}
                        handleLinkFormClear={handleLinkFormClear}
                    />
                </Col>
                <Col lg={4}>
                    <h3>
                        Links
                        <Button
                            className="btn-sm btn-outline-primary my-0 mx-3"
                            aria-label="Refresh link list"
                            onClick={refreshLinks}
                        >Refresh</Button>
                    </h3>
                    <List
                        ids={ids}
                        fields={[cats, names]}
                        buttons={['edit', 'delete']}
                        colChars={[10, 30]}
                        colWidths={[25, 50, 25]}
                        header={header}
                        sortHandler={handleLinkListSort}
                        buttonHandlers={[handleLinkEdit, handleLinkDelete]}
                        textIfEmpty={'Link list is empty - create links.'}
                    />
                </Col>
            </Row>
            {confirmDialogVisible && (
                <ConfirmationDialog
                    setDialog={handleDeleteConfirmation}
                    message={confirmDialogText}
                />
            )}
        </Container>
    )
}

LinkSubView.propTypes = {
    /** Link objects fetched from the database. */
    links: PropTypes.arrayOf(PropTypes.object),

    /** Function for setting the value of <i>links</i> hook. */
    setLinks: PropTypes.func,

    /** Function for refreshing <i>links</i> from the database. */
    refreshLinks: PropTypes.func,

    /** Function for displaying timed alert. */
    showAlert: PropTypes.func,

    /** Function for displaying alert that user has to dismiss. */
    showDismissibleAlert: PropTypes.func,

    /** Category objects fetched from the database. */
    categories: PropTypes.arrayOf(PropTypes.object),

    /** Function for setting the link list ordering. */
    setLinkOrderBy: PropTypes.func,

    /** Question objects fetched from the database. */
    questions: PropTypes.arrayOf(PropTypes.object)
}

export default LinkSubView
