// The index marking the point in the array that is being handled
let index = 0

/**
 * The function that checks if a given rule is true or false based on what
 * the user has answered. Used in UserView.
 * 
 * First spaces are added to rule string around parentheses, then the string
 * is split into an array containing each part of the rule (a parentheses,
 * logical operator or question:answer variable). Empty spaces are removed.
 * 
 * The global variable index is set to 0.
 * 
 * Empty rule is always true.
 *
 * @param {string} rule the rule that is checked
 * @param {Array} answers list of user's answers to all the previous questions
 * @public
 */
const check = (rule, answers) => {
    if (rule.length === 0) {
        return true
    }

    let r = rule.split('(').join(' ( ')
    r = r.split(')').join(' ) ')
    const ruleArray = r.split(' ').filter(r => r)
    index = 0
    
    return testRuleToAnswers(ruleArray, answers)
}

/**
 * The recursive function that goes through the rule array once.
 * 
 * If rule[index] is a question:answer variable, checks if it is
 * found from the list of user answers. Result (true/false) is saved
 * in value.
 * 
 * If rule[index] is a logical operator ('AND', 'OR', 'NOT'),
 * checks the next member in the array. If it is a question:answer variable,
 * checks if it is found from answers, and connects the result to value
 * with the logical operator (e.g. true AND false). The result of the logical
 * expression is saved in value.
 * 
 * If rule[index] is a '(', goes into a new recursion. If rule[index] is a ')',
 * returns value.
 * 
 * When loop finishes, returns value.
 */
const testRuleToAnswers = (rule, answers) => {
    let value = null

    while (index < rule.length) {
        if (rule[index] === '(') {
            index++
            value = testRuleToAnswers(rule, answers)
        } else if (rule[index] === ')') {
            return value
        } else if (rule[index] === 'NOT') {
            if (rule[index + 1] === '(') {
                index += 2
                let result = testRuleToAnswers(rule, answers)
                value = !result
            } else {
                value = !answers.includes(rule[index + 1])
                index++
            }
        } else if (rule[index] === 'AND') {
            if (rule[index + 1] === '(') {
                index += 2
                // IF RESULT NOT SEPARATELY DECLARED, WILL NOT GO TO NEW RECURSION!!!
                let result = testRuleToAnswers(rule, answers)
                value = value && result
            } else if (rule[index + 1] === 'NOT') {
                // if e.g. 1:1 AND NOT (2:1 OR 2:2)
                if (rule[index + 2] === '(') {
                    index += 3
                    let bracketsResult = testRuleToAnswers(rule, answers)
                    let negationResult = !bracketsResult
                    value = value && negationResult
                    // else e.g. 1:1 AND NOT 2:1
                } else {
                    let negationResult = !answers.includes(rule[index + 2])
                    value = value && negationResult
                    index += 2
                }
            } else {
                value = value && answers.includes(rule[index + 1])
                index++
            }
        } else if (rule[index] === 'OR') {
            if (rule[index + 1] === '(') {
                index += 2
                let result = testRuleToAnswers(rule, answers)
                value = value || result
            } else if (rule[index + 1] === 'NOT') {
                // if e.g. 1:1 OR NOT (2:1 OR 2:2)
                if (rule[index + 2] === '(') {
                    index += 3
                    let bracketsResult = testRuleToAnswers(rule, answers)
                    let negationResult = !bracketsResult
                    value = value || negationResult
                    // else e.g. 1:1 OR NOT 2:1
                } else {
                    let negationResult = !answers.includes(rule[index + 2])
                    value = value || negationResult
                    index += 2
                }
            } else {
                value = value || answers.includes(rule[index + 1])
                index++
            }
        } else {
            value = answers.includes(rule[index])
        }
        index++
    }
    return value
}

const functions = {
    check
}

export default functions
