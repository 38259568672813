import React from 'react'
import { useState, useEffect } from 'react'
import { Alert, Form, Col, Row, Button, Collapse, Accordion } from 'react-bootstrap'
import { Trash3, PlusSquareFill } from 'react-bootstrap-icons'
import PropTypes from 'prop-types'
import ruleSpellChecker from '../../logic/RuleSpellChecker'
import idsToIdentifiers from '../../logic/IdsToIdentifiers'
import existingChecker from '../../logic/ExistingChecker'
import { questionAnswerCodes, questionRuleSpecific, ruleLogic, nextQuestionsInstructions } from '../../common/ruleInstructions'

/**
 * A component for adding and editing survey questions. 
 *
 * Renders a form for adding and editing survey questions. If a question is selected, 
 * the form allows editing it. If no question is selected, a new question will be 
 * created on saving. Cancel button clears the form fields and unselects the selected 
 * question if there was one.  
 * 
 * QuestionForm.js component is used by the QuestionView.js in folder 'src/views'.
 * The view shows both QuestionForm and Questions components, and serves their 
 * functionalities (creating, reading, updating, deleting) to backend and database. 
 */

const QuestionForm = ({ createQuestion, updateQuestion, selectedQuestion, 
    cancelQuestion, questions }) => {

    // This is needed to display a question's details on the form
    // when the user selects some question
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {

            // clear rule errors and alerts
            setRuleErrors([])
            setNextQuestionErrors([])
            setAlert({ message: '', visible: false })
            
            if (selectedQuestion.id === '') {
                setIdentifier('')
                setNewCategory('')
                setNewText('')
                setNewType('single_choice')
                setNewHierarchical('all_true')
                setAnswerList([{ choice: '', answer_text: '', tip: '' }])
                setRuleList([{ position: '', question_rule: '', next_questions: '' }])
                setLastModified('')
            } else {
                setIdentifier(selectedQuestion.identifier)
                setNewCategory(selectedQuestion.identifier.substring(
                    0, selectedQuestion.identifier.lastIndexOf('-')))
                setNewText(selectedQuestion.question_text)
                setNewType(selectedQuestion.question_type)
                setNewHierarchical(selectedQuestion.hierarchical ? 'first_true' : 'all_true')
                setAnswerList(selectedQuestion.answers.length !== 0 
                    ? selectedQuestion.answers : [{ choice: '', answer_text: '', tip: '' }])             
                    setLastModified(selectedQuestion.last_modified)

                // SET LIST OF RULES:

                //initialize new table with required attributes
                let list = Array(selectedQuestion.rules.length).fill(
                    { id: 0, position: 0, question_rule: '', next_questions: '', question_id: 0 })
                // go through each rule separately
                for (let i = 0; i < selectedQuestion.rules.length; i++) {
                    // copy attributes that do not need to be changed
                    let newRule = { id: 0, position: 0, question_rule: '', next_questions: '', 
                        question_id: 0 }
                    newRule.id = selectedQuestion.rules[i].id
                    newRule.position = selectedQuestion.rules[i].position
                    newRule.question_id = selectedQuestion.rules[i].question_id

                    // change id --> identifier in next_questions. If not found, put error string
                    newRule.next_questions = idsToIdentifiers.nextQuestionsIdsToIdentifiers(
                        selectedQuestion.rules[i].next_questions, questions)

                    // Change id --> identifier in rule. If not found, put error string
                    newRule.question_rule = idsToIdentifiers.ruleIdsToIdentifiers(
                        selectedQuestion.rules[i].question_rule, questions)

                    list[i] = newRule
                }
                setRuleList(list.length !== 0 ? list : [{ position: '', question_rule: '', 
                    next_questions: '' }])

                // FINISHED SETTING LIST OF RULES
            }
        }

        // Set question form heading
        if (selectedQuestion.id === '') {
            setQuestionFormHeading(newQuestionHeading)
        } else {
            setQuestionFormHeading(editQuestionHeading)
        }

        return () => {
            isCancelled = true;
        }
    }, [selectedQuestion, questions])

    const newQuestionHeading = 'New question'
    const editQuestionHeading = 'Edit question'
    const formMarginY = 'my-1'
    const [questionFormHeading, setQuestionFormHeading] = useState(newQuestionHeading)
    const [identifier, setIdentifier] = useState('')   // id shown to end user, not real database id
    const [newCategory, setNewCategory] = useState('')
    const [newText, setNewText] = useState('')
    const [newType, setNewType] = useState('single_choice')
    const [newHierarchical, setNewHierarchical] = useState('all_true')
    const [answerList, setAnswerList] = useState([{ choice: '', answer_text: '', tip: '' }])
    const [ruleList, setRuleList] = useState([{ position: '', question_rule: '', next_questions: '' }])
    const [alert, setAlert] = useState({ message: '', visible: false })
    const [ruleErrors, setRuleErrors] = useState([])
    const [lastModified, setLastModified] = useState('')
    const [nextQuestionErrors, setNextQuestionErrors] = useState([])
    const [openInstructions, setOpenInstructions] = useState(false)

    // Form controllers
    const handleCategoryChange = (event) => {
        setNewCategory(event.target.value)
    }
    const handleTextChange = (event) => {
        setNewText(event.target.value)
    }
    const handleTypeChange = (event) => {
        setNewType(event.target.value)
    }
    const handleHierarchicalChange = (event) => {
        setNewHierarchical(event.target.value)
    }
    const handleAnswerAdd = () => {
        setAnswerList([...answerList, { choice: '', answer_text: '', tip: '' }])
    }
    const handleAnswerChange = (e, index) => {
        const { name, value } = e.target
        const list = [...answerList]
        list[index][name] = value
        setAnswerList(list)
    }
    const handleAnswerRemove = (e, index) => {
        const list = [...answerList]
        list.splice(index, 1)
        setAnswerList(list)
    }
    const handleRuleAdd = () => {
        setRuleList([...ruleList, { position: '', question_rule: '', next_questions: '' }])
    }
    const handleRuleChange = (e, index) => {
        const { name, value } = e.target
        const list = [...ruleList]
        list[index][name] = value
        setRuleList(list)
        
        if (name === 'question_rule' && (ruleErrors[index] || nextQuestionErrors[index])) {
            let errorsR = [...ruleErrors]
            errorsR[index] = []
            setRuleErrors(errorsR)
            
            let errorsQ = [...nextQuestionErrors]
            errorsQ[index] = []
            setNextQuestionErrors(errorsQ)
        }
    }
    const handleRuleRemove = (e, index) => {
        const list = [...ruleList]
        list.splice(index, 1)
        setRuleList(list)

        if (ruleErrors[index]) {
            const updateErrorList = [...ruleErrors]
            updateErrorList.splice(index, 1)
            setRuleErrors(updateErrorList)
        }
        if (nextQuestionErrors[index]) {
            const updateErrorList = [...nextQuestionErrors]
            updateErrorList.splice(index, 1)
            setNextQuestionErrors(updateErrorList)
        }
    }

    // Submitting of the form, when Save button is pressed
    const handleQuestion = (event) => {
        event.preventDefault()

        // Check that there are no errors in rule spelling
        {
            const [count, errors] = checkErrors()
            if (count > 0) {
                setAlert({ message: 'Fix errors in rules before submitting!', visible: true })
                setRuleErrors(errors)
                return
            }
        }
        
        // Check if all the questions in the rule exist
        {
            const [count, errors] = checkQuestionsExisting(questions)
            if (count > 0) {
                setAlert({ message: 'Some question(s) in the rule do(es) not exist, please see the rules.', visible: true })
                setRuleErrors(errors)
                return
            }
        }

        const questionObject = {
            question_category: newCategory,
            question_text: newText,
            question_type: newType,
            answers: answerList,
            hierarchical: newHierarchical === 'first_true' ? 'true' : 'false',
            rules: ruleList,
            last_modified: lastModified
        }

        // Check if all the answer options in the rule exist
        // NB! All the answer are assumed to be related to this question.
        {
            const [count, errors] = checkAnswersExisting(questionObject)
            if (count > 0) {
                setAlert({ message: 'Some answer option(s) in the rule(s) do(es) not exist, please see the rules.', visible: true })
                setRuleErrors(errors)
                return
            }
        }
        
        // Check if all the questions in the next question field exist
        {
            const [count, errors] = checkNextQuestionsExisting(questionObject)
            if (count > 0) {
                setAlert({ message: 'Some question(s) in the next question field do(es) not exist, please see below.', visible: true })
                setNextQuestionErrors(errors)
                return
            }
        }
        
        if (selectedQuestion.id === '') {
            createQuestion(questionObject)
        } else {
            updateQuestion(selectedQuestion, questionObject)
        }
    }

    // Clearing the form fields and unselecting question, when Cancel button is pressed.
    // Also closes visible alerts and error notifications. 
    const handleCancel = () => {
        cancelQuestion()
        closeAlert()
        clearErrorsInRules()
    }

    // Checks for errors in rule fields
    const checkErrors = () => {
        let errors = []
        let count = 0
        for (let i = 0; i < ruleList.length; i++) {
            const found = ruleSpellChecker.spellCheck(ruleList[i].question_rule)
            errors[i] = found
            if (found.length > 0) {
                count++
            }
        }
        return [count, errors]
    }
    
    const checkQuestionsExisting = () => {
        let errors = []
        let count = 0
        for (let i = 0; i < ruleList.length; i++) {
            const found = existingChecker.questionsExisting(ruleList[i].question_rule, questions)
            errors[i] = found
            if (found.length > 0) {
                count++
            }
        }
        return [count, errors]
    }
    
    const checkAnswersExisting = (questionObject) => {
        let errors = []
        let count = 0
        for (let i = 0; i < ruleList.length; i++) {
            const found = existingChecker.answersExisting(ruleList[i].question_rule, questionObject.answers)
            errors[i] = found
            if (found.length > 0) {
                count++
            }
        }
        return [count, errors]
    }
    
    const checkNextQuestionsExisting = (questionObject) => {
        let errors = []
        let count = 0
        for (let i = 0; i < ruleList.length; i++) {
            const found = existingChecker.nextQuestionsExisting(questionObject.rules, questions)
            errors[i] = found
            if (found.length > 0) {
                count++
            }
        }
        return [count, errors]
    }

    const closeAlert = () => {
        setAlert({ message: '', visible: false })
    }

    const clearErrorsInRules = () => {
        setRuleErrors([])
        setNextQuestionErrors([])        
    }

    return (
        <div>
            <Alert variant={'danger'} show={alert.visible}
                onClose={closeAlert} dismissible> {alert.message}
            </Alert>
            <h2> {questionFormHeading} </h2>
            <Form onSubmit={handleQuestion}>
                <Form.Group as={Row} controlId='questionIdentifier' className={formMarginY}>
                    <Form.Label column sm='3'>Identifier</Form.Label>
                    <Col sm='9'>
                        <Form.Control plaintext readOnly
                            value={identifier}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='questionCategory' className={formMarginY}>
                    <Form.Label column sm='3'>Category</Form.Label>
                    <Col sm='9'>
                        <Form.Control required
                            value={newCategory}
                            onChange={handleCategoryChange}
                            type='text' maxLength='255'
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='questionText' className={formMarginY}>
                    <Form.Label column sm='3'>Question text</Form.Label>
                    <Col sm='9'>
                        <Form.Control required as="textarea" rows={3}
                            value={newText}
                            onChange={handleTextChange}
                            type='text'
                        />
                    </Col>
                </Form.Group>
                <Accordion defaultActiveKey={['0', '1']} alwaysOpen>            
                    <Accordion.Item eventKey="0">
                        <Accordion.Header variant='rdm-acc'>Answers</Accordion.Header>
                        <Accordion.Body>
                            
                            <Form.Group controlId='questionType'>
                                <Row className={formMarginY}>
                                    <Form.Label column sm='4'>Type</Form.Label>
                                    <Col sm='4'>
                                        <Form.Check
                                            required
                                            inline
                                            value='single_choice'
                                            onChange={handleTypeChange}
                                            type='radio'
                                            label='Single choice'
                                            name='type'
                                            checked={newType === 'single_choice'}
                                        />
                                    </Col>
                                    <Col sm='4'>
                                        <Form.Check
                                            inline
                                            value='multiple_choices'
                                            onChange={handleTypeChange}
                                            type='radio'
                                            label='Multiple choices'
                                            name='type'
                                            checked={newType === 'multiple_choices'}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId='questionAnswerOptions' className={formMarginY}>
                                <Form.Label>Answer options</Form.Label>
                                {answerList.map((singleAnswer, index) => (
                                    <Row key={index} className={`answers ${formMarginY}`}>
                                        <Col sm='3'>
                                            <Form.Control
                                                required
                                                name='choice'
                                                type='text' maxLength='255'
                                                value={singleAnswer.choice}
                                                onChange={(e) => handleAnswerChange(e, index)}
                                                placeholder='option code'
                                            />
                                        </Col>
                                        <Col sm='4'>
                                            <Form.Control
                                                required
                                                name='answer_text'
                                                type='text'
                                                value={singleAnswer.answer_text}
                                                onChange={(e) => handleAnswerChange(e, index)}
                                                placeholder='option text'
                                            />
                                        </Col>
                                        <Col sm='3'>
                                            <Form.Control
                                                name='tip'
                                                type='text'
                                                value={singleAnswer.tip}
                                                onChange={(e) => handleAnswerChange(e, index)}
                                                placeholder='tip (optional)'
                                            />
                                        </Col>
                                        <Col sm='1'>
                                            <Button
                                                variant='btn-icon btn-remove'
                                                onClick={(e) => handleAnswerRemove(e, index)}>
                                                <Trash3 />
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                            </Form.Group>
                            <Button 
                                variant='btn btn-icon'
                                onClick={handleAnswerAdd}>
                                <PlusSquareFill />
                            </Button>                                
                        </Accordion.Body>
                    </Accordion.Item>                    
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Rules</Accordion.Header>
                        <Accordion.Body>
                                
                            <Form.Group controlId='questionHierarchical' className={formMarginY}>
                                <Row>
                                    <Form.Label column sm='4'>Hierarchical</Form.Label>
                                    <Col sm='4'>
                                        <Form.Check
                                            required
                                            inline
                                            value='all_true'
                                            onChange={handleHierarchicalChange}
                                            type='radio'
                                            id='hierarchical-all-true'
                                            label='All true'
                                            name='hierarchical'
                                            checked={newHierarchical === 'all_true'}
                                        />
                                    </Col>
                                    <Col sm='4'>
                                        <Form.Check
                                            inline
                                            value='first_true'
                                            onChange={handleHierarchicalChange}
                                            type='radio'
                                            id='hierarchical-first-true'
                                            label='First true'
                                            name='hierarchical'
                                            checked={newHierarchical === 'first_true'}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId='questionRules' className={formMarginY}>
                                <Form.Label>Rules</Form.Label>
                                <div>
                                    <Button
                                        className='btn-outline-primary'
                                        style={{ borderColor: '#fff', padding: '0em' }}
                                        size='sm'
                                        onClick={() => setOpenInstructions(!openInstructions)}>
                                        {openInstructions ? 'Hide instructions' : 'Open instructions'}
                                    </Button>
                                </div>
                                <Collapse in={openInstructions}>
                                    <Form.Text id='ruleHelpBlock' muted>
                                        <Row className={`rules ${formMarginY}`}>
                                            <Col style={{ whiteSpace: 'pre-line' }} sm='10'>
                                                <p>{questionAnswerCodes}</p>
                                                <p>{questionRuleSpecific}</p>
                                                <p>{ruleLogic}</p>
                                                <p>{nextQuestionsInstructions}</p>
                                            </Col>
                                        </Row>
                                    </Form.Text>
                                </Collapse>
                                {ruleList.map((singleRule, index) => (
                                    <Row key={index} className={`rules ${formMarginY}`}>
                                        <Col sm='3'>
                                            <Form.Control
                                                required
                                                name='position'
                                                type='number' min='0'
                                                value={singleRule.position}
                                                onChange={(e) => handleRuleChange(e, index)}
                                                placeholder='position'
                                            />
                                        </Col>
                                        <Col sm='4'>
                                            <Form.Control
                                                name='question_rule'
                                                type='text'
                                                value={singleRule.question_rule}
                                                onChange={(e) => handleRuleChange(e, index)}
                                                placeholder='rule'
                                                isInvalid={ruleErrors[index] && ruleErrors[index].length > 0}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {ruleErrors[index] && ruleErrors[index].map((error, i) =>  
                                                    <p key={i} >{error}</p>)}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col sm='3'>
                                            <Form.Control
                                                name='next_questions'
                                                type='text'
                                                value={singleRule.next_questions}
                                                onChange={(e) => handleRuleChange(e, index)}
                                                placeholder='next questions'
                                                isInvalid={nextQuestionErrors[index] && nextQuestionErrors[index].length > 0}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {nextQuestionErrors[index] && nextQuestionErrors[index].map((error, i) => 
                                                    <p key={i} >{error}</p>)}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col sm='1'>
                                            <Button
                                                variant='btn-icon btn-remove'
                                                onClick={(e) => handleRuleRemove(e, index)}>
                                                <Trash3 />
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                            </Form.Group>
                            <Button 
                                variant='btn btn-icon'
                                onClick={handleRuleAdd}>
                                <PlusSquareFill />
                            </Button>
                                
                        </Accordion.Body>
                    </Accordion.Item>    
                </Accordion>
                <div className='my-3'>
                    <Button id='save-button' type='submit'>Save</Button>
                    <Button variant='outline-primary' id='cancel-button' 
                        onClick={handleCancel}>Cancel</Button>
                </div>
            </Form>
        </div>
    )
}

QuestionForm.propTypes = {
    /**
     * Handler function that is called when pressing the Cancel button.
     * Used for clearing the form fields and unselecting a question. 
     * The functioning of this handler is defined in QuestionView.js.
     */
    cancelQuestion: PropTypes.func,

    /**
     * Handler function for creating a new question on form submit (on save).
     * The functioning of this handler is defined in QuestionView.js.
     */
    createQuestion: PropTypes.func,

    /**
     * A set of question items. The set is fetched from the database in QuestionView.js. 
     * In QuestionForm component, the question set is used for mapping the database ids to 
     * question identifiers in the question form's rules section. 
     */
    questions: PropTypes.array,

    /**
     * A question item that has been selected for viewing or editing on the question form.
     * The selection is made in QuestionView.js.
     */
    selectedQuestion: PropTypes.shape({
        answers: PropTypes.array,
        hierarchical: PropTypes.any,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]),
        identifier: PropTypes.string,
        last_modified: PropTypes.any,
        question_text: PropTypes.any,
        question_type: PropTypes.any,
        rules: PropTypes.array
    }),

    /**
     * Handler function for updating an existing question on form submit (on save).
     * The functioning of this handler is defined in QuestionView.js.
     */
    updateQuestion: PropTypes.func
}

export default QuestionForm