import { useState } from 'react'
import { Button, Col, Form, Row, Collapse } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import FormButtonRow from '../FormButtonRow'
import { questionAnswerCodes, ruleLogic } from '../../common/ruleInstructions'

/**
 * A component for editing feedback links on RDM Intro feedback view.
 * 
 * Renders a form for editing feedback links. Form is implemented as a
 * <a href="https://reactjs.org/docs/forms.html#controlled-components">
 * controlled component</a> and it requires hooks for the data on the form
 * inputs and handler functions for handling changes to these fields as props.
 * Editor title text and submit button are also passed as props.
 */
const LinkEditor = ({
    linkCategory,
    linkCategoryInputValue,
    linkCategoryOptions,
    linkRule,
    linkText,
    linkUrl,
    linkEditorHeading,
    linkEditorSubmitText,
    handleLinkCategoryChange,
    handleLinkCategoryInputChange,
    handleLinkRuleChange,
    handleLinkTextChange,
    handleLinkUrlChange,
    handleLinkSave,
    handleLinkFormClear
}) => {
    const [openInstructions, setOpenInstructions] = useState(false)
    return (
        <Form>
            <h3>{linkEditorHeading}</h3>
            <Form.Group as={Row} controlId="linkCategory" className="my-1">
                <Form.Label column sm="4">Category</Form.Label>
                <Col sm="8">
                    <CreatableSelect
                        isClearable
                        placeholder={'Select or create...'}
                        value={linkCategory}
                        inputValue={linkCategoryInputValue}
                        name="linkCategorySelect"
                        options={linkCategoryOptions}
                        onChange={handleLinkCategoryChange}
                        onInputChange={handleLinkCategoryInputChange}
                    />
                </Col>
            </Form.Group> 
            <Form.Group as={Row} controlId='linkRule' className="my-1">
                <Form.Label column sm="4">Rule for showing link</Form.Label>
                <Col sm="8">
                    <Form.Control
                        as="input"
                        type="text"
                        value={linkRule}
                        onChange={handleLinkRuleChange}
                    />
                </Col>
            </Form.Group>
            <div>
                <Button
                    className='btn-outline-primary'
                    style={{ borderColor: '#fff', padding: '0em' }}
                    size='sm'
                    onClick={() => setOpenInstructions(!openInstructions)}>
                    {openInstructions ? 'Hide rule instructions' : 'Open rule instructions'}
                </Button>
            </div>
            <Collapse in={openInstructions}>
                <Form.Text id='ruleHelpBlock' muted>
                    <Row className="py-0">
                        <Col style={{ whiteSpace: 'pre-line' }} sm='10'>
                            <p>{questionAnswerCodes}</p>
                            <p>{ruleLogic}</p>
                        </Col>
                    </Row>
                </Form.Text>
            </Collapse>
            <Form.Group as={Row} controlId='linkText' className="my-1">
                <Form.Label column sm="4">Link name</Form.Label>
                <Col sm="8">
                    <Form.Control
                        as="input"
                        type="text"
                        value={linkText}
                        onChange={handleLinkTextChange}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId='linkUrl' className="my-1">
                <Form.Label column sm="4">Link url</Form.Label>
                <Col sm="8">
                    <Form.Control
                        as="input"
                        type="text"
                        value={linkUrl}
                        onChange={handleLinkUrlChange}
                    />
                </Col>
            </Form.Group>
            <FormButtonRow>
                <Button
                    className="ml-0"
                    aria-label="Save new feedback link"
                    onClick={handleLinkSave}
                >{linkEditorSubmitText}</Button>
                <Button
                    aria-label="Clear feedback link editing form"
                    onClick={handleLinkFormClear}
                    className="btn-outline-primary"
                >Clear form</Button>
            </FormButtonRow>
        </Form>
    )
}

LinkEditor.propTypes = {
    /**
     * Hook for the link category selected using the form (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     * 
     * Hook is first initialized as an empty string after which its values are
     * objects. It is initialized as a string because if initialized as an
     * object React does not interpret this as a hook and complains that
     * <i>"A component is changing an uncontrolled input to be controlled.
     * This is likely caused by the value changing from undefined to a defined
     * value, which should not happen."</i>, when the hook gets a new value.
     * 
     * Hook is used in a react-select component and therefore object must be in
     * a format supported by it ({value: category.id, label:
     * category.category_name}). <strong>Notice that this is not the same
     * format as is used in the <i>links</i> array.</strong>
     */
     linkCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

     /**
      * Hook for the link category input contents. (see
      * <a href="https://reactjs.org/docs/forms.html#controlled-components">
      * controlled component</a>).
      */
     linkCategoryInputValue: PropTypes.string,
 
     /** Hook for the link category options in the react-select dropdown menu
      * (see
      * <a href="https://reactjs.org/docs/forms.html#controlled-components">
      * controlled component</a>).
      */
     linkCategoryOptions: PropTypes.arrayOf(PropTypes.object),
 
     /**
      * Hook for the link rule input contents. (see
      * <a href="https://reactjs.org/docs/forms.html#controlled-components">
      * controlled component</a>).
      */
     linkRule: PropTypes.string,
 
     /**
      * Hook for the link text input contents. (see
      * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
      */
     linkText: PropTypes.string,
 
     /** Title text of the editor form. */
     linkEditorHeading: PropTypes.string,
 
     /** Submit button text. */
     linkEditorSubmitText: PropTypes.string,
 
     /**
      * Handler function for <i>linkCategory</i> change (see
      * <a href="https://reactjs.org/docs/forms.html#controlled-components">
      * controlled component</a>).
      */
     handleLinkCategoryChange: PropTypes.func,
 
     /** Handler function for <i>linkCategoryInput</i> change (see
      * <a href="https://reactjs.org/docs/forms.html#controlled-components">
      * controlled component</a>).
      */
     handleLinkCategoryInputChange: PropTypes.func,
 
     /**
      * Handler function for link rule input change (see
      * <a href="https://reactjs.org/docs/forms.html#controlled-components">
      * controlled component</a>).
      */
     handleLinkRuleChange: PropTypes.func,
 
     /**
      * Handler function for link text input contents (see
      * <a href="https://reactjs.org/docs/forms.html#controlled-components">
      * controlled component</a>).
      */
     handleLinkTextChange: PropTypes.func,
 
     /** Handler function for submit button. */
     handleLinkSave: PropTypes.func,
 
     /** Handler function for clear form button. */
     handleLinkFormClear: PropTypes.func
}

export default LinkEditor