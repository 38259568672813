import axiosInstance from './axios'

let baseUrl = 'api/links/'

const getAll = (orderBy) => {
    const request = axiosInstance.get(baseUrl + '?order_by=' + orderBy)
    return request.then(response => response.data)
}

const createLink = (newLink) => { 
    const request = axiosInstance.post(baseUrl, newLink)
    return request.then(response => response.data)
}

const updateLink = (linkId, newLink) => {
    const request = axiosInstance.put(baseUrl + linkId + '/', newLink)
    return request.then(response => response)
}

const deleteLink = (linkId) => {
    const request = axiosInstance.delete(baseUrl + linkId)
    return request.then(response => response)
}

const functions = {
    getAll,
    createLink,
    updateLink,
    deleteLink
  }

export default functions