import axiosInstance from './axios'

let settingUrl = 'api/settings/'
let profileUrl = 'api/admins/'


const getAll = () => {
    const request = axiosInstance.get(settingUrl)
    return request.then(response => response.data)
}

const updateSettings = (settingsObject) => {
    const request = axiosInstance.post(settingUrl, settingsObject)
    return request.then(response => response)
}

const registerAdmin = (adminObject) => {
    const request = axiosInstance.post(profileUrl, adminObject)
    return request.then(response => response)
}

const functions = {
    getAll,
    updateSettings,
    registerAdmin,
}

export default functions