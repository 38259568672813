import { useState } from 'react'
import { Button, Container, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { InfoCircle } from 'react-bootstrap-icons'
import PropTypes from 'prop-types'

/**
 * A component for showing a single question to the user in the survey.
 * 
 * The component shows the question text and its answer options.
 * 
 * For answer options, the component provides togglable buttons that become 
 * selected when the user presses them. If the question type is defined as 
 * 'single choice', only one option may be chosen at a time, and the selection 
 * is automatically switched when the user presses another button. 
 * If the question type is defined as 'multiple choices', several options may be selected. 
 * In both question types the selection may be undone by pressing the same button again. 
 * 
 * When the user has selected at least one option, a Next button becomes enabled. 
 * It is disabled whenever there are no choices selected. When pressing an enabled Next button,
 * the next question in question pile is shown to the user.
 */
const QuestionCard = ({ question, getNextQuestion }) => {
    const [selectedButtons, setSelectedButtons] = useState([])

    const toggleButton = (id) => {
        let pressed = selectedButtons.filter(buttonId => buttonId === id)
        if (pressed.length === 0) {
            if (question.question_type === 'single_choice') {
                setSelectedButtons([id])
            } else {
                setSelectedButtons(selectedButtons.concat(id))
            }
        } else {
            setSelectedButtons(selectedButtons.filter(buttonId => buttonId !== id))
        }
    }

    /**
     * Call getNextQuestion() function from UserView to change the question.
     * State selectedButtons contains ids of answers that are selected.
     * Reset selectedButtons for a new question.
     */
    const moveToNextQuestion = () => {
        getNextQuestion(selectedButtons)
        setSelectedButtons([])
    }

    const renderAnswerButton = (answer, index) => (
        <Button
            className='w-100'
            variant={
                selectedButtons.includes(answer.id)
                    ? 'primary'
                    : 'outline-primary'
            }
            key={index}
            style={{
                margin: '0.3em',
                paddingTop: '0.8em',
                paddingLeft: '0.8em',
                paddingBottom: '0.9em',
                fontWeight: 'normal'
            }}
            onClick={() => toggleButton(answer.id)}>
            <Container fluid className='gx-0'>
                <Row className='align-items-center'>
                    <Col xs={{ span: 8, offset: 2 }}>
                        {answer.answer_text}
                    </Col>
                    <Col xs>
                        {answer.tip &&
                            <OverlayTrigger
                                key={index}
                                placement='right'
                                overlay={<Tooltip>{answer.tip}</Tooltip>}>
                                <InfoCircle />
                            </OverlayTrigger>}
                    </Col>
                </Row>
            </Container>
        </Button>
    )


    return (
        <div>
            {question &&
                <div style={{ whiteSpace: 'pre-line' }}>
                    <h4>
                        {question.question_text}
                    </h4>
                    <br></br>
                    <Container fluid='md'>
                        {question.answers.map((answer, index) => (
                            <Row key={index}>
                                <Col className='col-5'>
                                    {renderAnswerButton(answer, index)}
                                </Col>
                            </Row>
                        ))}
                    </Container>
                    <br></br>
                    <div>
                        <Button disabled={selectedButtons.length === 0}
                            variant={'primary'}
                            style={selectedButtons.length === 0 ?
                                {
                                    backgroundColor: '#e7e7e7',
                                    borderColor: '#e7e7e7',
                                    color: '#1a1c1f',
                                    display: 'inline-block'
                                } : {}
                            }
                            onClick={() => moveToNextQuestion()}>
                            Next
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}


QuestionCard.propTypes = {

    /**
     * Handler function for moving the user to answer to the next question.
     * The functioning of this handler is defined in UserView.js.
     */
    getNextQuestion: PropTypes.func,

    /**
     * The question that the user is answering to. 
     * The question item has the question text, type and answer options. 
     */
    question: PropTypes.shape({
        answers: PropTypes.array,
        question_text: PropTypes.any,
        question_type: PropTypes.string
    })
}

export default QuestionCard
