import axiosInstance from './axios'

let baseUrl = 'api/categories/'

const getAll = () => {
    const request = axiosInstance.get(baseUrl)
    return request.then(response => response.data)
}

const createCategory = (newCategory) => {
    const request = axiosInstance.post(baseUrl, newCategory)
    return request.then(response => response.data)
}

const updateCategory = (categoryId, newCategory) => {
    const request = axiosInstance.put(baseUrl + categoryId + '/', newCategory)
    return request.then(response => response)
}

const deleteCategory = (categoryId) => {
    const request = axiosInstance.delete(baseUrl + categoryId)
    return request.then(response => response)
}

const functions = {
    getAll,
    createCategory,
    updateCategory,
    deleteCategory
  }

export default functions