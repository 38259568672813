import PropTypes from 'prop-types'

/**
 * A component for showing a notification message to the user.
 * 
 * When calling the notification, a message text is provided. 
 * The message is shown in the notification component.
 * The component may be given a specific style or default style may be used. 
 * 
 * Notification.js component is used by the UserView.js in folder 'src/views'. 
 * It is used for showing notifications for the users who are filling out the survey.
 */
const Notification = ({ message, style }) => {

    const defaultStyle = {
        fontFamily: 'arial',
        background: '#f8f8f8',
        padding: 10,
        marginBottom: 10
    }

    return (
        <div>
            {message !== ''
            ? 
            <div style={!style ? defaultStyle : style}>
                {message}
            </div>
            : 
            <div></div>
            }
        </div>
    )
}


Notification.propTypes = {

    /**
     * A text string to be shown in the notification. 
     * The message text is given when the function is called.
     */
    message: PropTypes.string,
    
    /**
     * A style for the looks of the message. 
     * The style may be defined separately or a default style defined for this component 
     * may be used. 
     */
    style: PropTypes.any
}

export default Notification