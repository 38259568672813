import { useState } from 'react'
import settingService from '../services/settings'
import '../styles.css'
import { Alert, Container, Form, Col, Row, Button } from 'react-bootstrap'
import { Pencil} from 'react-bootstrap-icons'
import { Navigate } from 'react-router-dom'

/**
 * The view for admin user's personal password management.
 */
const ProfileView = () => {

    // Initialization
    const [alert, setAlert] = useState({message:'', variant:'', visible:false, dismissible:false})
    const [newAdminPassword, setNewAdminPassword] = useState('')
    const [currentAdminPassword, setCurrentAdminPassword] = useState('')

    // Alerts
    const showAlert = (alertMessage, alertVariant) => {
        setAlert({message: alertMessage, variant: alertVariant, visible: true})
        setTimeout(() => {
            setAlert({message: '', variant: '', visible: false})
        }, 5000)
    }

    const showDismissibleAlert = (alertMessage, alertVariant) => {
        setAlert({message: alertMessage, variant: alertVariant, visible: true, dismissible: true})
    }

    const closeDismissibleAlert = () => {
        setAlert({message: '', variant: '', visible: false, dismissible: false})
    }

    // Handlers for form field content changes
    const handleNewPasswordChange = (e) => {
        setNewAdminPassword(e.target.value)
    }

    const handleCurrentPasswordChange = (e) => {
        setCurrentAdminPassword(e.target.value)
    }

    // Saving the password change
    const handleSaveAdmin = (e) => {
        settingService.registerAdmin({username: window.localStorage.getItem('user'), current_password: currentAdminPassword, new_password: newAdminPassword})
            .then(response => {
                setNewAdminPassword('')
                setCurrentAdminPassword('')
                showAlert(window.localStorage.getItem('user')+' password changed', 'success')

            })
            .catch(error => {
                setNewAdminPassword('')
                setCurrentAdminPassword('')
                showDismissibleAlert('Password not changed', 'danger')
            })
    }

    if (!window.localStorage.getItem('access_token')) {
        return <Navigate to='/' />
    }

    return (
        <Container>
            <div>
            <Alert variant={alert.variant} show={alert.visible} dismissible={alert.dismissible} onClose={closeDismissibleAlert}> {alert.message}</Alert>
                <h1>Profile</h1>
            </div>

            <Form>
                <Form.Group controlId='NewAdministrator' className="d-grid gap-1">
                    <Form.Label column sm='4'>Change password:</Form.Label>
                    <Row className='admin name'>
                        <Col sm='4'> 
                            <input
                            className="form-control"
                            type="text"
                            autoComplete="username"
                            readOnly={true}
                            value = {window.localStorage.getItem('user')}/>
                        </Col>
                        <Col sm='1'>
                            <Button
                                variant='btn btn-icon'
                                onClick={(e) => handleSaveAdmin(e)}>
                                <Pencil/>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='admin new password'>
                        <Col sm='4'>
                        <input
                            className="form-control"
                            type="password"
                            placeholder='new password'
                            autoComplete="new-password"
                            value = {newAdminPassword}
                            onChange={(e) => handleNewPasswordChange(e)}/>
                        </Col>
                    </Row>
                    <Row className='admin current password'>
                        <Col sm='4'>
                            <input
                                className="form-control"
                                type="password"
                                placeholder='current password'
                                autoComplete="current-password"
                                value = {currentAdminPassword}
                                onChange={(e) => handleCurrentPasswordChange(e)}/>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Container>
    )
}

// Export the view to allow it to be used elsewhere
export default ProfileView