import { useState } from 'react'
import { Button, Container, Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import BlockEditor from './BlockEditor'
import List from '../List'
import blockService from '../../services/blocks'
import ConfirmationDialog from '../ConfirmDialog'
import {
    LIST_HEADER_CHAR_ASCENDING,
    LIST_HEADER_CHAR_DESCENDING
} from '../../common/constants'
import idChanger from '../../logic/IdsToIdentifiers'
import getListItemId from '../../common/getListItemId'

/**
 * Subview for editing feedback blocks on RDM Intro feedback view.
 * 
 * Blocks can be used for grouping related comments and links together on the
 * feedback generated for the user after he/she has submitted her/his answers.
 * Subview renders an editor form (<i>BlockEditor</i>) for creating and editing
 * blocks and a list of blocks already in the database (generated using
 * <i>List</i>). To make the rendering of the list possible the view must be
 * given an array of blocks already found from the database as a prop. Comment
 * and link selectors must also be given arrays of comments and links already
 * in the database. View also needs to refresh these lists and this is done
 * using <i>refreshBlocks()</i> function that is passed from the
 * <i>SubView</i> component of the <i>FeedbackView</i> as a prop. Props
 * <i>toCommentSubView</i> and <i>toLinkSubView</i> are functions that are also
 * coming from the SubView. They are passed to <i>BlockEditor</i> and used
 * there for switching subviews from links in the editor form.
 * 
 * An array of question objects fetched freshly from the database is also
 * needed, so that the identifiers used by the user in the rules can be changed
 * into valid database ids. Display of alerts is implemented in the
 * <i>SubView</i> component of the <i>FeedbackView</i> and it is used through
 * the functions in the <i>showAlert</i> and <i>showDismissibleAlert</i> props.
 */
const BlockSubView = ({ 
    blocks,
    comments,
    toCommentSubView,
    links,
    toLinkSubView,
    refreshBlocks,
    setBlocks,
    showAlert,
    showDismissibleAlert,
    setBlockOrderBy,
    questions
}) => {
    // -- Subview state -------------------------------------------------------

    const newBlockHeading = 'New block'
    const newBlockSubmitText = 'Create'
    const editBlockHeading = 'Edit block'
    const editBlockSubmitText = 'Save'
    const [blockName, setBlockName] = useState('')
    const [blockRule, setBlockRule] = useState('')
    const [blockComments, setBlockComments] = useState([])
    const [blockLinks, setBlockLinks] = useState([])
    const [blockId, setBlockId] = useState(0)
    const [blockEditorHeading, setBlockEditorHeading] = useState(
        newBlockHeading
    )
    const [blockEditorSubmitText, setBlockEditorSubmitText] = useState(
        newBlockSubmitText
    )
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
    const [confirmDialogText, setConfirmDialogText] = useState('')
    const [blockToDelete, setBlockToDelete] = useState(null)
    const [orderAscending, setOrderAscending] = useState(false)
    const [header, setHeader] = useState(['Name'])

    // -- Handler functions ---------------------------------------------------

    const handleNameChange = (event) => {
        setBlockName(event.target.value)
    }

    const handleRuleChange = (event) => {
        setBlockRule(event.target.value)
    }

    const handleBlockCommentsChange = (selectedComments) => {
        setBlockComments(selectedComments)
    }

    const handleBlockLinksChange = (selectedLinks) => {
        setBlockLinks(selectedLinks)
    }

    // Returns full comment objects when given blockComments as parameter
    // (objects in the blockComments are in a format accepted by react-select).
    const getComments = (arr) => {
        const commentArr = arr.map(rsObj =>
            comments.find(c => c.id.toString() === rsObj.value.toString())
        )
        return commentArr
    }

    // Returns full link objects when given blockLinks as parameter
    // (objects in the blockLinks are in a format accepted by react-select).
    const getLinks = (arr) => {
        const linkArr = arr.map(rsObj =>
            links.find(l => l.id.toString() === rsObj.value.toString())
        )
        return linkArr
    }

    const handleBlockSave = () => {
        const errorMessageCreate = <><strong>ERROR:</strong> Failed to create block.</>
        const errorMessageUpdate = <><strong>ERROR:</strong> Failed to update block.</>
        const successMessageCreate = <><strong>SUCCESS:</strong> New block created successfully.</>
        const successMessageUpdate = <><strong>SUCCESS:</strong> Block updated successfully.</>

        // Objects in blockComments and blockLinks are in react-select props format,
        // so real comment and link objects have to be fetched from comments and links
        // arrays.
        const commentsArr = getComments(blockComments)
        const linksArr = getLinks(blockLinks)

        // Default value for blockId is 0 and the ids in the db start from 1.
        // So, if blockId is now 0 we know this is a new db entry and we'll leave
        // the id field out of the object and use the create function. Otherwise we
        // update the entry in which case we must also include the id in the object.
        if (blockId !== 0) {
            const blockObj = {
                id: blockId,
                name: blockName,
                rule: blockRule,
                comments: commentsArr,
                links: linksArr
            }
            blockService
                .updateBlock(blockId, blockObj)
                .then(response => {
                    // clear the form and refresh block list
                    handleBlockFormClear()
                    refreshBlocks()
                    showAlert(successMessageUpdate, 'success')
                })
                .catch(error => {
                    refreshBlocks()
                    showDismissibleAlert(errorMessageUpdate, 'danger')
                })
        } else {
            const blockObj = {
                name: blockName,
                rule: blockRule,
                comments: commentsArr,
                links: linksArr
            }
            blockService
                .createBlock(blockObj)
                .then(response => {
                    // clear the form and refresh block list
                    handleBlockFormClear()
                    refreshBlocks()
                    showAlert(successMessageCreate, 'success')
                })
                .catch(error => {
                    refreshBlocks()
                    showDismissibleAlert(errorMessageCreate, 'danger')
                })
        }

        // Update object in blocks array or add a new block object with temporary data.
        if (blockId !== 0) {
            const newBlocks = blocks.slice()
            const index = newBlocks.findIndex(obj => obj.id === blockId)
            if (index in newBlocks) {
                newBlocks[index].name=blockName
                newBlocks[index].rule=blockRule
                newBlocks[index].comments=commentsArr
                newBlocks[index].links=linksArr
                newBlocks[index].id=blockId
            }
            setBlocks(newBlocks)
        }
        else {
            setBlocks(blocks.concat({id: 'saving', name: 'saving...'}))
        }
    }

    // Returns object in a format that can used as a react-select prop when given
    // comment or link object as parameter.
    const mapToSelectFormat = (objects) => {
        const ret = objects.map(object => {
            const obj = {}
            obj.value = object.id
            obj.label = object.text
            return obj
        })
        return ret
    }

    const handleBlockEdit = (event) => {
        const itemId = getListItemId(event)
        const block = blocks.find(b => (b.id.toString() === itemId))
        const commentsArrForSelector = mapToSelectFormat(block.comments)
        const linksArrForSelector = mapToSelectFormat(block.links)

        setBlockEditorHeading(editBlockHeading + ' ' + block.id)
        setBlockEditorSubmitText(editBlockSubmitText)
        setBlockName(block.name)
        setBlockRule(idChanger.ruleIdsToIdentifiers(block.rule, questions))
        setBlockComments(commentsArrForSelector)
        setBlockLinks(linksArrForSelector)
        setBlockId(block.id)
    }

    const handleBlockFormClear = () => {
        setBlockEditorHeading(newBlockHeading)
        setBlockEditorSubmitText(newBlockSubmitText)
        setBlockName('')
        setBlockRule('')
        setBlockComments([])
        setBlockLinks([])
        setBlockId(0)
    }

    const handleBlockDelete = (event) => {
        const itemId = getListItemId(event)
        updateConfirmDialog(
            'Are you sure you want to delete this block?',
            true,
            itemId
        )
    }

    // -- Delete confirmation dialog ------------------------------------------

    const updateConfirmDialog = (text, visible, bId) => {
        setConfirmDialogText(text)
        setConfirmDialogVisible(visible)
        setBlockToDelete(bId)
    }

    const handleDeleteConfirmation = (confirmed) => {
        if (confirmed) {
            deleteBlock()
            updateConfirmDialog('', false, null)
        }
        updateConfirmDialog('', false, null)
    }

    const deleteBlock = () => {
        const bId = blockToDelete

        blockService
            .deleteBlock(bId)
            .then(response => {
                // If the block we removed is in the editor, we must set it's id to
                // default value 0 and change blockEditorHeading.
                if (bId === blockId.toString()) {
                    setBlockId(0)
                    setBlockEditorHeading(newBlockHeading)
                }
                refreshBlocks()})
            .catch(error => {
                refreshBlocks()
            })
    }

    // -- Block list sorting --------------------------------------------------

    // Removes wedge characters indicating the direction of the sort from the
    // end of the string text.
    const clearHeaderField = (text, charAsc, charDesc) => {
        const lastChar = text.slice(-1)
        if (lastChar === charAsc || lastChar === charDesc) {
            return text.slice(0, text.length-2)
        }
        return text
    }

    const handleBlockListSort = (event) => {
        const charAsc = LIST_HEADER_CHAR_ASCENDING
        const charDesc = LIST_HEADER_CHAR_DESCENDING

        const headerIndex = Number(event.target.getAttribute('value'))
        const field = header[headerIndex]
        const lastChar = field.slice(-1)
        const fieldBase = (lastChar === charAsc || lastChar === charDesc )
            ? field.slice(0, field.length-2)
            : field
        const newOrder = (orderAscending)
            ? fieldBase.toLowerCase()
            : '-' + fieldBase.toLowerCase()
        setBlockOrderBy(newOrder)

        const newDirectionChar = (orderAscending) ? charAsc : charDesc
        const newHeader = header.map(
            text => clearHeaderField(text, charAsc, charDesc)
        )
        newHeader[headerIndex] = fieldBase + ' ' + newDirectionChar
        setHeader(newHeader)
        
        if (orderAscending) {
            setOrderAscending(false)
        } else {
            setOrderAscending(true)
        }
    }

    // -- Prepare props for List ----------------------------------------------

    const names = blocks.map(block => block.name)
    const ids = blocks.map(block => block.id)

    // -- Return JSX ----------------------------------------------------------

    return (
        <Container>
            <Row>
                <Col lg={8} className="mb-3">
                    <BlockEditor
                        blockName={blockName}
                        blockRule={blockRule}
                        blockComments={blockComments}
                        blockLinks={blockLinks}
                        blockEditorHeading={blockEditorHeading}
                        blockEditorSubmitText={blockEditorSubmitText}
                        handleNameChange={handleNameChange}
                        handleRuleChange={handleRuleChange}
                        handleBlockCommentsChange={handleBlockCommentsChange}
                        handleBlockLinksChange={handleBlockLinksChange}
                        handleSave={handleBlockSave}
                        handleClear={handleBlockFormClear}
                        comments={comments}
                        toCommentSubView={toCommentSubView}
                        links={links}
                        toLinkSubView={toLinkSubView}
                    />
                </Col>
                <Col lg={4}>
                    <h3>
                        Blocks
                        <Button
                            className="btn-sm btn-outline-primary my-0 mx-3"
                            aria-label="Refresh block list"
                            onClick={refreshBlocks}
                        >Refresh</Button>
                    </h3>
                    <List
                        ids={ids}
                        fields={[names]}
                        buttons={['edit', 'delete']}
                        colChars={[30]}
                        colWidths={[75, 25]}
                        header={header}
                        sortHandler={handleBlockListSort}
                        buttonHandlers={[handleBlockEdit, handleBlockDelete]}
                        textIfEmpty={'Block list is empty - create blocks.'}
                    />
                </Col>
            </Row>
            {confirmDialogVisible && (
                <ConfirmationDialog
                    setDialog={handleDeleteConfirmation}
                    message={confirmDialogText}
                />
            )}
        </Container>
    )
}

BlockSubView.propTypes = {
    /** Block objects fetched from the database. */
    blocks: PropTypes.arrayOf(PropTypes.object),

    /** Comment objects fetched from the database. */
    comments: PropTypes.arrayOf(PropTypes.object),

    /**
     * Function used for switching to <i>CommentSubView</i> from the editor
     * links.
     */
    toCommentSubView: PropTypes.func,

    /** Link objects fetched from the database. */
    links: PropTypes.arrayOf(PropTypes.object),

    /** Function for switching to <i>LinkSubView</i> from the editor links. */
    toLinkSubView: PropTypes.func,

    /** Function for requesting refresh of the data in the blocks hook. */
    refreshBlocks: PropTypes.func,

    /** Function for setting the blocks hook. */
    setBlocks: PropTypes.func,

    /** Function for displaying a timed alert. */
    showAlert: PropTypes.func,

    /**
     * Function for displaying an alert that the user must dismiss her/himself.
     */
    showDismissibleAlert: PropTypes.func,

    /**
     * Function for setting the column by which the ordering of the list is
     * done.
     */
    setBlockOrderBy: PropTypes.func,

    /** Question objects fetched from the database. */
    questions: PropTypes.arrayOf(PropTypes.object)
}

export default BlockSubView
