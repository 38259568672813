import { useEffect } from 'react';
import axiosInstance from '../services/axios';
import { useNavigate } from 'react-router-dom';

const Logout = ({ setAuth }) => {
    const navigate = useNavigate();

    useEffect(() => {
        setAuth(false)
        navigate('/login');
    });
    axiosInstance
        .post('api/logout/', { refresh_token: localStorage.getItem('refresh_token'), })
        .catch((err) => {
            console.log(err)
        })
    window.localStorage.removeItem('access_token')
    window.localStorage.removeItem('refresh_token')
    axiosInstance.defaults.headers['Authorization'] = null
}

export default Logout