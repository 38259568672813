import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

/**
 * A component for displaying a confirmation dialog.
 * 
 * Component renders a confirmation dialog with text given in the
 * <i>message</i> prop and 'yes' and 'no' options as buttons. Component needs
 * also a handler function as a prop (<i>setDialog</i>).
 */
function ConfirmationDialog({ message, setDialog }) {
    return (
        <div className="ConfirmDialogBackground" onClick={() => setDialog(false)}>
            <div onClick={(e) => e.stopPropagation()} className="confirmDialogBox">
                <div className="confirmationDialogText">{message}</div>

                <div className="confirmationDialogButtonAlignment">
                    <div className="confirmationDialogButton">
                        <Button onClick={() => setDialog(true)}>Yes</Button>
                    </div>
                    <div className="confirmationDialogButton">
                        <Button onClick={() => setDialog(false)}>No</Button>
                    </div>
                </div>
            </div>
        </div>
    );
  }

ConfirmationDialog.propTypes = {
    /** Message displayd on the dialog. */
    message: PropTypes.string,

    /** Handler function for the dialog. */
    setDialog: PropTypes.func
}

export default ConfirmationDialog
