import { useEffect, useState } from 'react'
import settingService from '../services/settings'
import questionService from '../services/questions'
import '../styles.css'
import { Alert, Container, Form, Col, Row, Button } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'

/**
 * The view for managing general settings for the survey.
 * 
 * In this view, admins can set the landing page title and text and starting questions
 * for the survey. The settings are managed via form fields.
 */
const SettingView = () => {

    // State hooks
    const [landing_page_title, setLandingPageTitle] = useState('')
    const [landing_page_text, setLandingPageText] = useState('')
    const [starting_question, setStartingQuestion] = useState('')
    const [alert, setAlert] = useState({message:'', variant:'', visible:false, dismissible:false})
    
    // Alert message texts
    const successMessageUpdate = <><strong>SUCCESS:</strong> Settings updated successfully.</>
    const errorMessageResourceNotFound = <><strong>ERROR:</strong> Resource not found. 
        Please check that the starting question identifier refers to an existing question.</>
    const errorMessageUpdate = <><strong>ERROR:</strong> Something went wrong while updating the settings.</>

    // Initialization    
    useEffect(() => {
        setLandingPageTitle("Loading...")
        setLandingPageText("Loading...")
        setStartingQuestion("Loading...")

        const errorMessageFetching = <><strong>ERROR:</strong> Failed to fetch the settings.</>

        settingService
            .getAll()
            .then(settings => {
                if (!settings.filter(setting => setting.setting_name === "landing_page_title")[0]){
                    setLandingPageTitle('Title has not been set')
                    showDismissibleAlert(errorMessageFetching, 'danger')
                }
                else {
                    setLandingPageTitle(settings.filter(setting => setting.setting_name === "landing_page_title")[0].setting_value)
                }
                if (!settings.filter(setting => setting.setting_name === "landing_page_text")[0]) {
                    setLandingPageText('Text has not been set')
                    showDismissibleAlert(errorMessageFetching, 'danger')
                }
                else {
                    setLandingPageText(settings.filter(setting => setting.setting_name === "landing_page_text")[0].setting_value)
                }
                if (!settings.filter(setting => setting.setting_name === "starting_question")[0]) {
                    setStartingQuestion('Starting question has not been set')
                    showDismissibleAlert(errorMessageFetching, 'danger')
                }
                else {
                    questionService
                        .getQuestion({ id: settings.filter(setting => setting.setting_name === "starting_question")[0].setting_value })
                        .then(question => {
                            setStartingQuestion(question.identifier)
                        })
                        .catch(error => {
                            if (error.response.status === 404) {
                                setStartingQuestion('QUESTION NOT FOUND, SET NEW QUESTION HERE')
                            } else {
                                setStartingQuestion('SOMETHING WENT WRONG')
                            }
                        })
                    }
            })
            .catch(error => {
                    console.log('Error in fetching the settings values. ' + error)
                    showDismissibleAlert(errorMessageFetching, 'danger')
            })
    }, [])

    // Alerts
    const showAlert = (alertMessage, alertVariant) => {
        setAlert({message: alertMessage, variant: alertVariant, visible: true})
        setTimeout(() => {
            setAlert({message: '', variant: '', visible: false})
        }, 5000)
    }

    const showDismissibleAlert = (alertMessage, alertVariant) => {
        setAlert({message: alertMessage, variant: alertVariant, visible: true, dismissible: true})
    }

    const closeDismissibleAlert = () => {
        setAlert({message: '', variant: '', visible: false, dismissible: false})
    }

    // Handlers for form field content changes
    const handleTitleChange = (event) => {
        setLandingPageTitle(event.target.value)
    }

    const handleTextChange = (event) => {
        setLandingPageText(event.target.value)
    }

    const handleStartingQuestionChange = (event) => {
        setStartingQuestion(event.target.value)
    }

    // Saving the settings
    const handleSave = (event) => {
        event.preventDefault()
        const settingObject = [
            { setting_name: "landing_page_title", setting_value: landing_page_title },
            { setting_name: "landing_page_text", setting_value: landing_page_text },
            { setting_name: "starting_question", setting_value: starting_question },
        ]
        updateSettings(settingObject)
    }

    const updateSettings = (settingObject) => {
        settingService
            .updateSettings(settingObject)
            .then(response => {
                console.log('Settings were updated.')
                showAlert(successMessageUpdate, 'success')
            })
            .catch(error => {
                console.log('Failed to update settings. ' + error)
                if (error.response.status === 404) { 
                    showDismissibleAlert(errorMessageResourceNotFound, 'danger')
                } else {
                    showDismissibleAlert(errorMessageUpdate, 'danger')
                }
            })
    }

    if (!window.localStorage.getItem('access_token')) {
        return <Navigate to='/' />
    }

    // Generate and return the web page using functions above
    return (
        <Container>
            <div>
                <h1>Settings</h1>
            </div>
            <Alert variant={alert.variant} show={alert.visible} dismissible={alert.dismissible} onClose={closeDismissibleAlert}> {alert.message}</Alert>
            <Form onSubmit={handleSave}>
                <Form.Group as={Row} controlId='settingsLandingPageTitle'>
                    <Form.Label column sm='4'>Landing page title</Form.Label>
                    <Col sm='8'>
                        <Form.Control
                            value={landing_page_title}
                            onChange={handleTitleChange}
                            type='text'
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='settingsLandingPageText'>
                    <Form.Label column sm='4'>Landing page text</Form.Label>
                    <Col sm='8'>
                        <Form.Control as="textarea" rows={3}
                            value={landing_page_text}
                            onChange={handleTextChange}
                            type='text'
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId='settingsStartingQuestion'>
                    <Form.Label column sm='4'>Starting question</Form.Label>
                    <Col sm='8'>
                        <Form.Control
                            value={starting_question}
                            onChange={handleStartingQuestionChange}
                            type='text'
                        />
                    </Col>
                </Form.Group>
                <div>
                    <Button id='save-button' type='submit'>Save</Button>
                </div>
            </Form>
        </Container>
    )
}

// Export the view to allow it to be used elsewhere
export default SettingView