import { useState } from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import CommentEditor from './CommentEditor'
import List from '../List'
import commentService from '../../services/comments'
import { 
    CATEGORY_MAX_LENGTH,
    LIST_HEADER_CHAR_ASCENDING,
    LIST_HEADER_CHAR_DESCENDING
} from '../../common/constants'
import ConfirmationDialog from '../ConfirmDialog'
import idChanger from '../../logic/IdsToIdentifiers'
import getListItemId from '../../common/getListItemId'

/**
 * Subview for editing feedback comments on RDM Intro feedback view.
 * 
 * Comments are text fragments used as parts of the feedback that is generated
 * for the user after he/she has submitted her/his answers. Subview renders an
 * editor form for creating and editing comments (<i>CommentEditor</i>) and a
 * list of comments that are already in the database (generated using
 * <i>List</i>). To make the rendering of the list possible the component must
 * be given an array of comments freshly fetched from the database as a prop.
 * View also needs to refresh these lists and this is done using
 * <i>refreshBlocks()</i> function that comes from the <i>SubView</i> component
 * of the <i>FeedbackView</i>. Props <i>toCommentSubView</i> and
 * <i>toLinkSubView</i> are functions also coming from the SubView component.
 * They are passed to <i>BlockEditor</i> and used there for switching subviews
 * from links in the editor form.
 * 
 * An array of question objects fetched freshly from the database is also
 * needed, so that the identifiers used by the user in the rules can be changed
 * into valid database ids. Display of alerts is implemented in the
 * <i>SubView</i> component of the <i>FeedbackView</i> and it is used through
 * the functions in the <i>showAlert</i> and <i>showDismissibleAlert</i> props.
 */
const CommentSubView = ({
    comments,
    setComments,
    refreshComments,
    showAlert,
    showDismissibleAlert,
    categories,
    setCommentOrderBy,
    questions
}) => {
    // -- Subview state -------------------------------------------------------

    const newCommentHeading = 'New comment'
    const newCommentSubmitText = 'Create'
    const editCommentHeading = 'Edit comment'
    const editCommentSubmitText = 'Save'
    const [commentCategory, setCommentCategory] = useState('')
    const [commentCategoryInputValue, setCommentCategoryInputValue] = useState(
        ''
    )
    const [commentRule, setCommentRule] = useState('')
    const [commentText, setCommentText] = useState('')
    const [commentId, setCommentId] = useState(0)
    const [commentEditorHeading, setCommentEditorHeading] = useState(
        newCommentHeading
    )
    const [commentEditorSubmitText, setCommentEditorSubmitText] = useState(
        newCommentSubmitText
    )
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
    const [confirmDialogText, setConfirmDialogText] = useState('')
    const [commentToDelete, setCommentToDelete] = useState(null)
    const [orderAscending, setOrderAscending] = useState(false)
    const [header, setHeader] = useState(['Category', 'Text'])

    // -- Handler functions ---------------------------------------------------

    const handleCommentCategoryChange = (selectedCategoryOption) => {
        setCommentCategory(selectedCategoryOption)
    }

    const handleCommentCategoryInputChange = (inputValue) => {
        const value = (inputValue.length <= CATEGORY_MAX_LENGTH)
            ? inputValue
            : inputValue.substr(0, CATEGORY_MAX_LENGTH)
        setCommentCategoryInputValue(value)
    }

    const handleCommentRuleChange = (event) => {
        setCommentRule(event.target.value)
    }

    const handleCommentTextChange = (event) => {
        setCommentText(event.target.value)
    }

    const handleCommentSave = () => {
        const errorMessageCreate = (
            <><strong>ERROR:</strong> Failed to create comment.</>
        )
        const errorMessageUpdate = (
            <><strong>ERROR:</strong> Failed to update comment.</>
        )
        const successMessageCreate = (
            <><strong>SUCCESS:</strong> New comment created successfully.</>
        )
        const successMessageUpdate = (
            <><strong>SUCCESS:</strong> Comment updated successfully.</>
        )
        const badRequestMessageCreate = (
            <><strong>ERROR:</strong> Failed to create comment. Comment has to have a category.</>
        )

        // Default value for commentId is 0 and the ids in the db start from 1.
        // So, if commentId is now 0 we know this is a new db entry and we'll leave
        // the id field out of the object and use the create function. Otherwise we
        // update the entry in which case we must also include the id in the object.
        if (commentId !== 0) {
            const commentObj = {
                id: commentId,
                text: commentText,
                rule: commentRule,
                category: commentCategory.label
            }
            commentService
                .updateComment(commentId, commentObj)
                .then(response => {
                    handleCommentFormClear()
                    refreshComments()
                    showAlert(successMessageUpdate, 'success')
                })
                .catch(error => {
                    refreshComments()
                    showDismissibleAlert(errorMessageUpdate, 'danger')
                })
        } else {
            const commentObj = {
                text: commentText,
                rule: commentRule,
                category: commentCategory.label
            }
            commentService
                .createComment(commentObj)
                .then(response => {
                    handleCommentFormClear()
                    refreshComments()
                    showAlert(successMessageCreate, 'success')
                })
                .catch(error => {
                    if (error.code === 'ERR_BAD_REQUEST') {
                        showDismissibleAlert(badRequestMessageCreate, 'danger')
                    }
                    else {
                        showDismissibleAlert(errorMessageCreate, 'danger')
                    }
                    refreshComments()
                })
        }

        // update object in comments array or add a new comment object with temporary data
        if (commentId !== 0) {
            const newComments = comments.slice()
            const index = newComments.findIndex(obj => obj.id === commentId)
            newComments[index].category.id=commentCategory.value
            newComments[index].category.category_name=commentCategory.label
            newComments[index].text=commentText
            newComments[index].rule=commentRule
            newComments[index].id=commentId
            setComments(newComments)
        }
        else {
            setComments(comments.concat(
                {id: 'saving',
                text: 'saving...',
                category: {id: 'saving...', category_name: 'saving...'}
            }))
        }
    }

    const handleCommentFormClear = () => {
        setCommentEditorHeading(newCommentHeading)
        setCommentEditorSubmitText(newCommentSubmitText)
        setCommentCategory('')
        setCommentCategoryInputValue('')
        setCommentRule('')
        setCommentText('')
        setCommentId(0)
    }

    const handleCommentEdit = (event) => {
        const itemId = getListItemId(event)
        const comment = comments.find(c => c.id.toString() === itemId)

        refreshComments()

        setCommentEditorHeading(editCommentHeading + ' ' + comment.id)
        setCommentEditorSubmitText(editCommentSubmitText)
        setCommentCategory({
            value: comment.category ? comment.category.id : 'null',
            label: comment.category ? comment.category.category_name : 'null'
        })
        setCommentRule(idChanger.ruleIdsToIdentifiers(comment.rule, questions))
        setCommentText(comment.text)
        setCommentId(comment.id)
    }

    const handleCommentDelete = (event) => {
        const itemId = getListItemId(event)
        updateConfirmDialog(
            'Are you sure you want to delete this comment?',
            true,
            itemId
        )
    }

    // -- Delete confirmation dialog ------------------------------------------

    const updateConfirmDialog = (text, visible, commId) => {
        setConfirmDialogText(text)
        setConfirmDialogVisible(visible)
        setCommentToDelete(commId)
    }

    const handleDeleteConfirmation = (confirmed) => {
        if (confirmed) {
            deleteComment()
            updateConfirmDialog('', false, null)
        }
        updateConfirmDialog('', false, null)
    }

    const deleteComment = () => {
        const commId = commentToDelete

        commentService
            .deleteComment(commId)
            .then(response => {
                // If the comment we removed is in the editor, we must set it's id to
                // default value 0 and change commentEditorHeading.
                if (commId === commentId.toString()) {
                    setCommentId(0)
                    setCommentEditorHeading(newCommentHeading)
                }
                refreshComments()
            })
            .catch(error => {
                refreshComments()
            })
    }

    // -- Comment list sorting ------------------------------------------------

    // Removes wedge characters indicating the direction of the sort from the
    // end of the string text.
    const clearHeaderField = (text, charAsc, charDesc) => {
        const lastChar = text.slice(-1)
        if (lastChar === charAsc || lastChar === charDesc) {
            return text.slice(0, text.length-2)
        }
        return text
    }

    const handleCommentListSort = (event) => {
        const charAsc = LIST_HEADER_CHAR_ASCENDING
        const charDesc = LIST_HEADER_CHAR_DESCENDING

        const headerIndex = Number(event.target.getAttribute('value'))
        const field = header[headerIndex]
        const lastChar = field.slice(-1)
        const fieldBase = (lastChar === charAsc || lastChar === charDesc )
            ? field.slice(0, field.length-2)
            : field
        const newOrder = (orderAscending)
            ? fieldBase.toLowerCase()
            : '-' + fieldBase.toLowerCase()
        setCommentOrderBy(newOrder)

        const newDirectionChar = (orderAscending) ? charAsc : charDesc
        const newHeader = header.map(
            text => clearHeaderField(text, charAsc, charDesc)
        )
        newHeader[headerIndex] = fieldBase + ' ' + newDirectionChar
        setHeader(newHeader)
        
        if (orderAscending) {
            setOrderAscending(false)
        } else {
            setOrderAscending(true)
        }
    }

    // -- Prepare props for subcomponents -------------------------------------

    // Category array must be mapped to the format that is accepted by
    // react-select as options.
    const categoryOptions = categories.map(category => ({
        value: category.id,
        label: category.category_name
    }))

    // Props for List component
    const cats = comments.map(
        comment => comment.category ? comment.category.category_name : '-'
    )
    const names = comments.map(comment => comment.text)
    const ids = comments.map(comment => comment.id)

    // -- Return JSX ----------------------------------------------------------

    return (
        <Container>
            <Row>
                <Col lg={8} className="mb-3">
                    <CommentEditor
                        commentCategory={commentCategory}
                        commentCategoryInputValue={commentCategoryInputValue}
                        commentCategoryOptions={categoryOptions}
                        commentRule={commentRule}
                        commentText={commentText}
                        commentEditorHeading={commentEditorHeading}
                        commentEditorSubmitText={commentEditorSubmitText}
                        handleCommentCategoryChange={
                            handleCommentCategoryChange
                        }
                        handleCommentCategoryInputChange={
                            handleCommentCategoryInputChange
                        }
                        handleCommentRuleChange={handleCommentRuleChange}
                        handleCommentTextChange={handleCommentTextChange}
                        handleCommentSave={handleCommentSave}
                        handleCommentFormClear={handleCommentFormClear}
                    />
                </Col>
                <Col lg={4}>
                    <h3>
                        Comments
                        <Button
                            className="btn-sm btn-outline-primary my-0 mx-3"
                            aria-label="Refresh comment list"
                            onClick={refreshComments}
                        >Refresh</Button>
                    </h3>
                    <List
                        ids={ids}
                        fields={[cats, names]}
                        buttons={['edit', 'delete']}
                        colChars={[10, 20]}
                        colWidths={[25, 50, 25]}
                        header={header}
                        sortHandler={handleCommentListSort}
                        buttonHandlers={[
                            handleCommentEdit,
                            handleCommentDelete
                        ]}
                        textIfEmpty={
                            'Comment list is empty - create comments.'
                        }
                    />
                </Col>
            </Row>
            {confirmDialogVisible && (
                <ConfirmationDialog
                    setDialog={handleDeleteConfirmation}
                    message={confirmDialogText}
                />
            )}
        </Container>
    )
}

CommentSubView.propTypes = {
    /** Comment objects fetched from the database. */
    comments: PropTypes.arrayOf(PropTypes.object),

    /** Function for setting the value of <i>comments</i> hook. */
    setComments: PropTypes.func,

    /** Function for refreshing <i>comments</i> from the database. */
    refreshComments: PropTypes.func,

    /** Function for displaying timed alert. */
    showAlert: PropTypes.func,

    /** Function for displaying alert that user has to dismiss. */
    showDismissibleAlert: PropTypes.func,

    /** Category objects fetched from the database. */
    categories: PropTypes.arrayOf(PropTypes.object),

    /** Function for setting the comment list ordering. */
    setCommentOrderBy: PropTypes.func,

    /** Question objects fetched from the database. */
    questions: PropTypes.arrayOf(PropTypes.object)
}

export default CommentSubView