
const questionsExisting = (ruleList, questions) => {
    
    let notExistingQuestions = []
    
    const ruleArray = getRuleArray(ruleList)
    const identifiers = questions.map(x => x.identifier)
    
    for (let i = 0; i < ruleArray.length; i++) {
        if (ruleArray[i].includes(':')) {
            const question = ruleArray[i].split(':')[0]
            
            if (!identifiers.includes(question)) {
                notExistingQuestions.push(question)
            }
        }
    }
    return notExistingQuestions
}

const answersExisting = (ruleList, answers) => {
    
    let notExistingAnswers = []
    
    const ruleArray = getRuleArray(ruleList)
    const answerChoices = answers.map(x => x.choice)
    
    for (let i = 0; i < ruleArray.length; i++) {
        if (ruleArray[i].includes(':')) {
            const answer = ruleArray[i].split(':')[1]
            
            if (!answerChoices.includes(answer)) {
                notExistingAnswers.push(answer)
            }
        } else if (ruleArray[i] !== 'AND' && 
                ruleArray[i] !== 'OR' && 
                ruleArray[i] !== 'NOT' && 
                ruleArray[i] !== ' ( ' && 
                ruleArray[i] !== ' ) ') 
            {
            //Then assume, that the element is an answer option of this question
            const answer = ruleArray[i]
            
            if (!answerChoices.includes(answer)) {
                notExistingAnswers.push(answer)
            }
        }
    }
    return notExistingAnswers
}

const nextQuestionsExisting = (rules, questions) => {
    
    let notExistingQuestions = []

    const nextQuestions = rules.map(x => x.next_questions)
    const identifiers = questions.map(x => x.identifier)
    
    for (let i = 0; i < nextQuestions.length; i++) {            
        if (!identifiers.includes(nextQuestions[i]) && !nextQuestions[i].includes('NEW') && nextQuestions[i] !== '') {
            notExistingQuestions.push(nextQuestions[i])
        }
    }
    return notExistingQuestions
}

const getRuleArray = (rule) => {
    let r = rule.split('(').join(' ( ')
    r = r.split(')').join(' ) ')
    const ruleArray = r.split(' ').filter(r => r)
    return ruleArray
}

const functions = {
    questionsExisting,
    answersExisting,
    nextQuestionsExisting
}

export default functions
