import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import UserView from './views/UserView'
import QuestionView from './views/QuestionView'
import ProfileView from './views/ProfileView'
import SettingView from './views/SettingView'
import FeedbackView from './views/FeedbackView'
import LogInView from './views/LogInView'
import Logout from './components/logout'
import { Container, Navbar, Nav } from 'react-bootstrap'
import { useState, useEffect } from 'react'

let baseURL = ''
if (process.env.REACT_APP_PRODUCTION === 'true') {
    baseURL = process.env.REACT_APP_API_URL
} else {
    baseURL = 'http://localhost:8000/'
}

const Home = () => (
    <div>
        <UserView />
    </div>
)

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(window.localStorage.getItem('access_token'))

    useEffect(() => {
        if (window.localStorage.getItem('access_token')) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }, [])

    const setAuth = (value) => {
        setIsAuthenticated(value)
    }

    const navLink = { color: 'white', textDecoration: 'none' }

    return (
        <div>
            <Router>
                <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark' >
                    <Container>
                        <Navbar.Brand as={Link} to='/'>RDM Intro</Navbar.Brand>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            <Nav className='mr-auto'>
                                <Nav.Link href='#' as='span'>
                                    <Link style={navLink} to='/'>Home</Link>
                                </Nav.Link>
                                {isAuthenticated
                                    ? <>
                                        <Nav.Link href='#' as='span'>
                                            <Link style={navLink} to='/profile'>Profile</Link>
                                        </Nav.Link>
                                        <Nav.Link href='#' as='span'>
                                            <Link style={navLink} to='/settings'>Settings</Link>
                                        </Nav.Link>
                                        <Nav.Link href='#' as='span'>
                                            <Link style={navLink} to='/questions'>Questions</Link>
                                        </Nav.Link>
                                        <Nav.Link href='#' as='span'>
                                            <Link style={navLink} to='/feedback'>Feedback</Link>
                                        </Nav.Link>
                                        <Nav.Link href='#' as='span'>
                                            <a href={baseURL+'admin/'} target="_blank" rel="noreferrer noopener" style={navLink}>Admins</a>
                                        </Nav.Link>
                                        <Nav.Link href='#' as='span'>
                                            <a href="https://version.helsinki.fi/rdmintro/rdmintro/-/blob/staging/documents/RDMIntro_user_manual.pdf" target="_blank" rel="noreferrer noopener" style={navLink}>Help</a>
                                        </Nav.Link>
                                        <Nav.Link href='#' as='span'>
                                            <Link style={navLink} to='/logout'>Logout</Link>
                                        </Nav.Link>
                                    </>
                                    : <Nav.Link href='#' as='span'>
                                        <Link style={navLink} to='/login'>Login</Link>
                                    </Nav.Link>}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Container className='pt-3 pb-5'>
                    <Routes>
                        <Route path='/profile' element={<ProfileView />} />
                        <Route path='/settings' element={<SettingView />} />
                        <Route path='/questions' element={<QuestionView />} />
                        <Route path='/feedback' element={<FeedbackView />} />
                        <Route path='/login' element={<LogInView setAuth={setAuth} />} />
                        <Route path='/logout' element={<Logout setAuth={setAuth} />} />
                        <Route path='/' element={<Home />} />
                    </Routes>
                </Container>
            </Router>
        </div>
    )
}

export default App;
