import { useState } from 'react'
import { Button, Col, Form, Row, Collapse } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import FormButtonRow from '../FormButtonRow'
import { questionAnswerCodes, ruleLogic } from '../../common/ruleInstructions'

/**
 * A component for editing feedback comments on RDM Intro feedback view.
 * 
 * Renders a form for editing feedback comments. Form is implemented as a
 * <a href="https://reactjs.org/docs/forms.html#controlled-components">
 * controlled component</a> and it requires hooks for the data on the form
 * inputs and handler functions for handling changes to these fields as props.
 * Editor title text and submit button are also passed as props.
 */
const CommentEditor = ({
    commentCategory,
    commentCategoryInputValue,
    commentCategoryOptions,
    commentRule,
    commentText,
    commentEditorHeading,
    commentEditorSubmitText,
    handleCommentCategoryChange,
    handleCommentCategoryInputChange,
    handleCommentRuleChange,
    handleCommentTextChange,
    handleCommentSave,
    handleCommentFormClear
}) => {
    const [openInstructions, setOpenInstructions] = useState(false)
    return (
        <Form>
            <h3>{commentEditorHeading}</h3>
            <Form.Group as={Row} controlId="commentCategory" className="my-1">
                <Form.Label column sm="4">Category</Form.Label>
                <Col sm="8">
                    <CreatableSelect
                        isClearable
                        placeholder={'Select or create...'}
                        value={commentCategory}
                        inputValue={commentCategoryInputValue}
                        name="commentCategorySelect"
                        options={commentCategoryOptions}
                        onChange={handleCommentCategoryChange}
                        onInputChange={handleCommentCategoryInputChange}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId='commentRule' className="my-1">
                <Form.Label column sm="4">Rule for showing comment</Form.Label>
                <Col sm="8">
                    <Form.Control
                        as="input"
                        type="text"
                        value={commentRule}
                        onChange={handleCommentRuleChange}
                    />
                </Col>
            </Form.Group>
            <div>
                <Button
                    className='btn-outline-primary'
                    style={{ borderColor: '#fff', padding: '0em' }}
                    size='sm'
                    onClick={() => setOpenInstructions(!openInstructions)}>
                    {openInstructions ? 'Hide rule instructions' : 'Open rule instructions'}
                </Button>
            </div>
            <Collapse in={openInstructions}>
                <Form.Text id='ruleHelpBlock' muted>
                    <Row className="py-0">
                        <Col style={{ whiteSpace: 'pre-line' }} sm='10'>
                            <p>{questionAnswerCodes}</p>
                            <p>{ruleLogic}</p>
                        </Col>
                    </Row>
                </Form.Text>
            </Collapse>
            <Form.Group as={Row} controlId='commentText' className="my-1">
                <Form.Label column sm="4">Comment text</Form.Label>
                <Col sm="8">
                    <Form.Control
                        as="textarea"
                        rows="3"
                        value={commentText}
                        onChange={handleCommentTextChange}
                    />
                </Col>
            </Form.Group>
            <FormButtonRow>
                <Button
                    className="ml-0"
                    aria-label="Save new feedback comment"
                    onClick={handleCommentSave}
                >{commentEditorSubmitText}</Button>
                <Button
                    aria-label="Clear feedback comment editing form"
                    onClick={handleCommentFormClear}
                    className="btn-outline-primary"
                >Clear form</Button>
            </FormButtonRow>
        </Form>
    )
}

CommentEditor.propTypes = {
    /**
     * Hook for the comment category selected using the form (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     * 
     * Hook is first initialized as an empty string after which its values are
     * objects. It is initialized as a string because if initialized as an
     * object React does not interpret this as a hook and complains that
     * <i>"A component is changing an uncontrolled input to be controlled.
     * This is likely caused by the value changing from undefined to a defined
     * value, which should not happen."</i>, when the hook gets a new value.
     * 
     * Hook is used in a react-select component and therefore object must be in
     * a format supported by it ({value: category.id, label:
     * category.category_name}). <strong>Notice that this is not the same
     * format as is used in the <i>comments</i> array.</strong>
     */
    commentCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * Hook for the comment category input contents. (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    commentCategoryInputValue: PropTypes.string,

    /** Hook for the comment category options in the react-select dropdown menu
     * (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    commentCategoryOptions: PropTypes.arrayOf(PropTypes.object),

    /**
     * Hook for the comment rule input contents. (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    commentRule: PropTypes.string,

    /**
     * Hook for the comment text input contents. (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
    * controlled component</a>).
     */
    commentText: PropTypes.string,

    /** Title text of the editor form. */
    commentEditorHeading: PropTypes.string,

    /** Submit button text. */
    commentEditorSubmitText: PropTypes.string,

    /**
     * Handler function for <i>commentCategory</i> change (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleCommentCategoryChange: PropTypes.func,

    /** Handler function for <i>commentCategoryInput</i> change (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleCommentCategoryInputChange: PropTypes.func,

    /**
     * Handler function for comment rule input change (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleCommentRuleChange: PropTypes.func,

    /**
     * Handler function for comment text input contents (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleCommentTextChange: PropTypes.func,

    /** Handler function for submit button. */
    handleCommentSave: PropTypes.func,

    /** Handler function for clear form button. */
    handleCommentFormClear: PropTypes.func
}

export default CommentEditor