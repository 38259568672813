import { useEffect, useState, useRef } from 'react'
import questionService from '../services/questions'
import Questions from '../components/Questions/Questions'
import QuestionForm from '../components/Questions/QuestionForm'
import ConfirmDialog from '../components/ConfirmDialog'
import { Alert, Container, Col, Row } from 'react-bootstrap'
import '../styles.css'
import { Navigate } from 'react-router-dom'


/**
 * The view for managing questions of the survey. 
 * 
 * The view has two child components: QuestionForm and Questions. 
 * QuestionForm is used for adding and editing questions. 
 * Questions is a list component for showing all survey questions. 
 * 
 * The view handles functionalities for deleting and selecting a question 
 * from the list in Questions component, and adding and editing questions 
 * via QuestionForm component. 
 * 
 * The view also manages success and error alerts for successful or failed
 * database operations in both its child components. 
 */
const QuestionView = () => {
    
    // State hooks used throughout the file
    const [questions, setQuestions] = useState([{ id: '', identifier: '', question_text: '' }])
    const [alert, setAlert] = useState({message:'', variant:'', visible:false, dismissible:false})
   
    // Alert message texts
    const successMessageCreate = <><strong>SUCCESS:</strong> New question created successfully.</>
    const successMessageUpdate = <><strong>SUCCESS:</strong> Question updated successfully.</>

    const errorMessageSelecting = <><strong>ERROR:</strong> Failed to select the question.</>
    const errorMessageDelete = <><strong>ERROR:</strong> Question could not be deleted.</>
    const errorMessageSave = <><strong>ERROR:</strong> Question could not be saved.</>
    const errorMessageQuestionNotExisting = <><strong>ERROR:</strong> Question does not exist.</>        
    const errorMessageRuleAnswerIdNotUnique = <><strong>ERROR:</strong> Question could not be saved. 
        Rule field contains an answer option that refers to multiple items.</>
    const errorMessageRuleAnswerIdNotExisting = <><strong>ERROR:</strong> Question could not be saved. 
        Rule field contains an answer option that does not exist.</>
    const errorMessageNextFaulty = <><strong>ERROR:</strong> Question could not be saved. 
        Next questions field contains a faulty identifier.</>
    const errorMessageChangedByAnother = <><strong>ERROR:</strong> Question was already updated by someone else.
        'Please reload question.'</>
    
    // Response status texts for checking purposes
    const responseMessageRuleNextQIdTransform = 'Error in next questions in rules: transforming question identifiers to database IDs failed.'
    const responseMessageRuleAnswerIdNotUnique = 'Error in a rule: answer option identifier refers to an ID that is not unique.'
    const responseMessageRuleAnswerIdNotExists = 'Error in a rule: answer option identifier refers to an ID that does not exist.'
    const responseMessageChangedByAnother = 'Question was already updated by someone else. Please reload question.'

    // Initialization
    useEffect(() => {
        const errorMessageFetching = <><strong>ERROR:</strong> Failed to fetch the questions.</>
        questionService
            .getAll()
            .then(questions => {
                setQuestions(questions)
            })
            .catch(error => {
                console.log('Error in fetching questions.' + error)
                showDismissibleAlert(errorMessageFetching, 'danger')
            })
    }, [])

    // Alerts 
    const showAlert = (alertMessage, alertVariant) => {
        setAlert({message: alertMessage, variant: alertVariant, visible: true, dismissible: false})
        setTimeout(() => {
            closeAlert()
        }, 5000)
    }

    const showDismissibleAlert = (alertMessage, alertVariant) => {
        setAlert({message: alertMessage, variant: alertVariant, visible: true, dismissible: true})
    }

    const closeAlert = () => {
        setAlert({message: '', variant: '', visible: false, dismissible: false})
    }

    // Selecting a question
    const [selectedQuestion, setSelectedQuestion] = useState({
        id: '',
        identifier: '',
        question_text: '',
        question_category: '',
        question_type: 'single_choice',
        hierarchical: false,
        answers: [{ choice: '', answer_text: '', tip: '' }],
        rules: [{ position: '', question_rule: '', next_questions: '' }],
        last_modified: '',
    })

    // This is needed so that callbacks inside functions can get the current state 
    // of selectedQuestion, not the state that it had when the function started.
    // E.g. when the function selectQuestion() starts, selectedQuestion has some value. 
    // After the function has retrieved the new question from the server, it needs to 
    // check that question against selectedQuestion which might have already changed. 
    // Without selectedQuestionRef, the function would check the retrieved question 
    // against the original (old) value of selectedQuestion, not the one it might have
    // changed into
    const selectedQuestionRef = useRef();
    selectedQuestionRef.current = selectedQuestion;

    const selectQuestion = ({ question }) => {

        // First we set the question from the list of questions on the client onto the form. 
        // This is fast since we have it on the client and do not get it over the newtwork.
        setSelectedQuestion(question)

        // We also get the same question from the server and check that the question 
        // we set above is still up to date. If the question has not changed, 
        // we do nothing. If the question had changed on the server, we replace 
        // the data on the form with this data. This is to make the system work faster.
        // In most cases the question has not changed, this is just to make sure. 
        // We check that the two questions are the same here to avoid an asynchronous mess.
        questionService
            .getQuestion(question)
            .then(retrievedQuestion => {
                let objectsAreEqual = true
                let keys1 = [];
                for (let key1 in question) {
                    keys1.push(key1);
                }
                let keys2 = [];
                for (let key2 in retrievedQuestion) {
                    keys2.push(key2);
                }
                if (keys1.length !== keys2.length) {
                    objectsAreEqual = false;
                }
                if (objectsAreEqual) {
                    for (let key_a in keys1) {
                        if (!keys2.includes(keys1[key_a])) {
                            objectsAreEqual = false;
                        }
                    }
                }
                if (objectsAreEqual) {
                    for (let key_b in keys2) {
                        if (!keys1.includes(keys2[key_b])) {
                            objectsAreEqual = false;
                        }
                    }
                }
                if (objectsAreEqual) {
                    for (let key in keys1) {
                        if (Array.isArray(question[keys1[key]])) {
                            if (JSON.stringify(question[keys1[key]]) !== JSON.stringify(retrievedQuestion[keys1[key]])) {
                                objectsAreEqual = false;
                            }
                        }
                        else {
                            if (question[keys1[key]] !== retrievedQuestion[keys1[key]]) {
                                objectsAreEqual = false;
                            }
                        }
                    }
                }
                if (!objectsAreEqual) {
                    setQuestions(questions.map(question => question.id !== retrievedQuestion.id ? question : retrievedQuestion))
                }
                if (!objectsAreEqual && selectedQuestionRef.current.id === retrievedQuestion.id) {
                    setSelectedQuestion(retrievedQuestion)
                }
            })
            .catch(error => {
                console.log('Error in selecting the question.' + error)
                cancelFunction()
                if (error.response.status === 404) {
                    let errorMessageNotFound = <><strong>ERROR:</strong> Question '{question.identifier}: {question.question_text}' cannot be found.</>
                    showDismissibleAlert(errorMessageNotFound, 'danger')
                    setQuestions(
                        questions.filter(q => q.id !== question.id)
                    )
                } else {
                    showDismissibleAlert(errorMessageSelecting, 'danger')
                }
            })
    }

    const emptyQuestion = {
        id: '',
        identifier: '',
        question_text: '',
        question_category: '',
        question_type: 'single_choice',
        hierarchical: false,
        answers: [{ choice: '', answer_text: '', tip: '' }],
        rules: [{ position: '', question_rule: '', next_questions: '' }],
        last_modified: '',
    }

    // Cancel adding or selecting a question via cancel button 
    const cancelFunction = () => {
        setSelectedQuestion(emptyQuestion)
        closeAlert()
    }

    // Adding a question
    const addQuestion = (newQuestionObject) => {
        questionService
            .addNewQuestion(newQuestionObject)
            .then(returnedQuestions => {
                setQuestions(questions.concat(returnedQuestions.questions))
                showAlert(successMessageCreate, 'success')
                setSelectedQuestion(emptyQuestion)
            })
            .catch(error => {
                console.log('Error in creating a new question.', error)
                if (error.response.status === 400 & error.response.statusText === responseMessageRuleAnswerIdNotUnique) {
                    showDismissibleAlert(errorMessageRuleAnswerIdNotUnique, 'danger')
                } else if (error.response.status === 400 & error.response.statusText === responseMessageRuleAnswerIdNotExists) {
                    showDismissibleAlert(errorMessageRuleAnswerIdNotExisting, 'danger')
                } else if (error.response.status === 400 & error.response.statusText === responseMessageRuleNextQIdTransform) {
                    showDismissibleAlert(errorMessageNextFaulty, 'danger')
                } else {
                    showDismissibleAlert(errorMessageSave, 'danger')
                }
            })
    }

    // Updating a question
    const changeQuestion = (questionToChange, changedQuestionObject) => {
        const questionToUpdate = questionToChange
        const updatedQuestionObject = changedQuestionObject

        questionService
            .updateQuestion(questionToUpdate, updatedQuestionObject)
            .then(returnedQuestions => {
                if (returnedQuestions.questions[0].id !== questionToChange.id) {
                    throw new Error('Question does not exist.')
                }              
                setQuestions(questions.map(question => question !== questionToUpdate ? question : returnedQuestions.questions[0]))
                setQuestions(questions => questions.concat(returnedQuestions.questions.slice(1)))
                showAlert(successMessageUpdate, 'success')
                setSelectedQuestion(emptyQuestion)
            })
            .catch(error => {
                console.log('Failed to update question.', error)                
                if (error.response.status === 404) {
                    showDismissibleAlert(errorMessageQuestionNotExisting, 'danger')
                    setQuestions(
                        questions.filter(q => q.id !== questionToUpdate.id)
                    )
                } else if (error.response.status === 400 & error.response.statusText === 
                        responseMessageRuleAnswerIdNotUnique) {
                    showDismissibleAlert(errorMessageRuleAnswerIdNotUnique, 'danger')
                } else if (error.response.status === 400 & error.response.statusText === 
                        responseMessageRuleAnswerIdNotExists) {
                    showDismissibleAlert(errorMessageRuleAnswerIdNotExisting, 'danger')
                } else if (error.response.status === 400 & error.response.statusText === 
                        responseMessageRuleNextQIdTransform) {
                    showDismissibleAlert(errorMessageNextFaulty, 'danger')
                } else if (error.response.status === 400 & error.response.statusText === 
                        responseMessageChangedByAnother) {
                    showDismissibleAlert(errorMessageChangedByAnother, 'danger')
                }
                else {
                    showDismissibleAlert(errorMessageSave, 'danger')
                }
            })
    }

    // Deleting a question
    const [questionToDelete, setQuestionToDelete] = useState({})
    const questionToDeleteRef = useRef();
    questionToDeleteRef.current = questionToDelete;
    const [confirmDialog, setConfirmDialog] = useState({ message: '', isLoading: false });

    const handleDelete = (question) => {
        setQuestionToDelete(question);
        handleConfirmDialog('Are you sure you want to delete this question?', true);
    };

    const handleConfirmDialog = (message, isLoading) => {
        setConfirmDialog({
            message,
            isLoading
        });
    };

    const deletionConfirmation = (deletionConfirmed) => {
        if (deletionConfirmed) {
            removeQuestion(questionToDeleteRef.current);
            handleConfirmDialog('', false);
        } else {
            handleConfirmDialog('', false);
        }
    };

    // objectToRemove = props, contains question
    const removeQuestion = ({ question }) => {
        questionService
            .deleteQuestion(question)
            .then(
                setQuestions(
                    questions.filter(q => q.id !== question.id)
                )
            )
            .catch(error => {
                console.log('Failed to delete question.', error)                
                if (error.response.status === 404) {
                    showDismissibleAlert(errorMessageQuestionNotExisting, 'danger')
                } else {
                    showDismissibleAlert(errorMessageDelete, 'danger')
                }
            })
        // question form fields are made empty if deleted question was selected to the form
        if (question.id === selectedQuestion.id) {
            setSelectedQuestion(emptyQuestion)
        }
    }

    if (!window.localStorage.getItem('access_token')) {
        return <Navigate to='/' />
    }


    // Generate and return the web page using functions above
    return (
        <Container>
            <h1> Questions </h1>
            <Alert variant={alert.variant} show={alert.visible} dismissible={alert.dismissible} onClose={closeAlert}> {alert.message}</Alert>
            <Row>
                <Col sm={8}>
                    <QuestionForm createQuestion={addQuestion} updateQuestion={changeQuestion}
                        selectedQuestion={selectedQuestion} cancelQuestion={cancelFunction}
                        questions={questions} />
                </Col>
                <Col sm={4}>
                    <h2>Question list</h2>
                    <Questions questions={questions} deleteFunction={handleDelete} selectFunction={selectQuestion} />
                </Col>
            </Row>
            {confirmDialog.isLoading && (
                <ConfirmDialog
                    setDialog={deletionConfirmation}
                    message={confirmDialog.message}
                />
            )}
        </Container>
    )
}

// Export the view to allow it to be used elsewhere
export default QuestionView
