import { useState, useEffect } from 'react'
import { Container, Nav, Alert } from 'react-bootstrap'
import BlockSubView from '../components/Feedback/BlockSubView'
import CommentSubView from '../components/Feedback/CommentSubView'
import LinkSubView from '../components/Feedback/LinkSubView'
import commentService from '../services/comments'
import linkService from '../services/links'
import blockService from '../services/blocks'
import categoryService from '../services/categories'
import questionService from '../services/questions'
import { Navigate } from 'react-router-dom'

// Feedback view has three subviews which are conditionally rendered using
// this component. Component sets state hooks for comment, link, block,
// category and question data. These are passed to subviews and their contents
// are loaded asynchronically using useEffect() hooks. Reloading of the data
// can be requested from subcomponents by calling refreshComments(),
// refreshLinks() and refreshBlocks() functions that are passed to them as
// props.
const SubView = ({
    subView,
    setSubView,
    showAlert,
    showDismissibleAlert
}) => {
    // -- Common state for the subviews ---------------------------------------

    const [ comments, setComments ] = useState([])
    const [ links, setLinks ] = useState([])
    const [ blocks, setBlocks ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [ questions, setQuestions ] = useState([])
    const [ refreshCommentsVar, setRefreshCommentsVar ] = useState(0)
    const [ refreshLinksVar, setRefreshLinksVar ] = useState(0)
    const [ refreshBlocksVar, setRefreshBlocksVar ] = useState(0)
    const [ commentOrderBy, setCommentOrderBy ] = useState(null)
    const [ linkOrderBy, setLinkOrderBy ] = useState(null)
    const [ blockOrderBy, setBlockOrderBy ] = useState(null)

    // -- Hooks for loading data asynchronically ------------------------------

    useEffect(() => {
        commentService
            .getAll(commentOrderBy)
            .then(comments => {
                setComments(comments)
            })
    }, [refreshCommentsVar, commentOrderBy])
    useEffect(() => {
        linkService
            .getAll(linkOrderBy)
            .then(links => {
                setLinks(links)
            })
    }, [refreshLinksVar, linkOrderBy])
    useEffect(() => {
        blockService
            .getAll(blockOrderBy)
            .then(blocks => {
                setBlocks(blocks)
            })
    }, [refreshBlocksVar, refreshCommentsVar, refreshLinksVar, blockOrderBy])
    useEffect(() => {
        categoryService
            .getAll()
            .then(categories => {
                setCategories(categories)
            })
    }, [refreshCommentsVar, refreshLinksVar])
    useEffect(() => {
        questionService
            .getAll()
            .then(questions => {
                setQuestions(questions)
        })
    }, [])

    // -- Functions passed to the subviews as props ---------------------------

    const toCommentSubView = (e) => {
        e.preventDefault()
        setSubView('comments')
    }

    const toLinkSubView = (e) => {
        e.preventDefault()
        setSubView('links')
    }

    const refreshComments = () => {
        setRefreshCommentsVar(refreshCommentsVar + 1)
    }

    const refreshLinks = () => {
        setRefreshLinksVar(refreshLinksVar + 1)
    }

    const refreshBlocks = () => {
        setRefreshBlocksVar(refreshBlocksVar + 1)
    }

    // -- Return JSX conditionally --------------------------------------------

    switch(subView) {
        case 'blocks':
            return (
                <BlockSubView
                    blocks={blocks}
                    comments={comments}
                    toCommentSubView={toCommentSubView}
                    links={links}
                    toLinkSubView={toLinkSubView}
                    refreshBlocks={refreshBlocks}
                    setBlocks={setBlocks}
                    showAlert={showAlert}
                    showDismissibleAlert={showDismissibleAlert}
                    setBlockOrderBy={setBlockOrderBy}
                    questions={questions}
                />
            )
        case 'comments':
            return (
                <CommentSubView
                    comments={comments}
                    setComments={setComments}
                    refreshComments={refreshComments}
                    showAlert={showAlert}
                    showDismissibleAlert={showDismissibleAlert}
                    categories={categories}
                    setCommentOrderBy={setCommentOrderBy}
                    questions={questions}
                />
            )
        case 'links':
            return (
                <LinkSubView
                    links={links}
                    setLinks={setLinks}
                    refreshLinks={refreshLinks}
                    showAlert={showAlert}
                    showDismissibleAlert={showDismissibleAlert}
                    categories={categories}
                    setLinkOrderBy={setLinkOrderBy}
                    questions={questions}
                />
            )
        default:
            return null
    }
}

/**
 * A view for managing questionnaire feedback.
 * 
 * After submitting her/his answers the user will receive feedback according to
 * the answers and rules defined by questionnaire administrator. Feedback
 * consists of comments and links and it can be structured using blocks. This
 * view renders functionality to manage these feedback elements. View has
 * three subviews that the user can switch using the tabs on the top of the
 * view. Each subview provides tools for managing one of the elements (blocks,
 * comments or links). These views are rendered using their own components:
 * <i>BlockSubView</i>, <i>CommentSubView</i> and <i>LinkSubView</i>.
*/
const FeedbackView = () => {
    // -- Subview and alert state ---------------------------------------------

    const [subView, setSubView] = useState('blocks')
    const [alertText, setAlertText] = useState('')
    const [alertVariant, setAlertVariant] = useState('')
    const [alertVisible, setAlertVisible] = useState(false)
    const [dismissibleAlertText, setDismissibleAlertText] = useState('')
    const [dismissibleAlertVariant, setDismissibleAlertVariant] = useState('')
    const [dismissibleAlertVisible, setDismissibleAlertVisible] = useState(
        false
    )

    // -- Alert handling ------------------------------------------------------

    const showAlert = (text, variant) => {
        setAlertText(text)
        setAlertVariant(variant)
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
            setAlertText('')
            setAlertVariant('')
        }, 5000)
    }

    const showDismissibleAlert = (text, variant) => {
        setDismissibleAlertText(text)
        setDismissibleAlertVariant(variant)
        setDismissibleAlertVisible(true)
    }

    const closeDismissibleAlert = () => {
        setDismissibleAlertText('')
        setDismissibleAlertVisible(false)
    }

    // -- Handling of the navigation between subviews -------------------------

    const handleNav = (event) => {
        switch(event) {
            case 'blocks':
                setSubView('blocks')
                break
            case 'comments':
                setSubView('comments')
                break
            case 'links':
                setSubView('links')
                break
            default:
                return
        }
    }

    // -- Access handling -----------------------------------------------------

    if (!window.localStorage.getItem('access_token')) {
        return <Navigate to='/' />
    }

    // -- Return JSX ----------------------------------------------------------

    return (
        <Container>
            <h1 className="rdm-view-main-title mb-3">Feedback</h1>
            <Alert
                variant={alertVariant}
                show={alertVisible}
            >
                {alertText}
            </Alert>
            <Alert
                variant={dismissibleAlertVariant}
                show={dismissibleAlertVisible}
                onClose={closeDismissibleAlert}
                dismissible
            >
                {dismissibleAlertText}
            </Alert>
            <Nav
                variant="tabs"
                activeKey={subView}
                onSelect={handleNav}
                className="my-4 mx-0"
            >
                <Nav.Item>
                    <Nav.Link eventKey="blocks">Blocks</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="comments">Comments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="links">Links</Nav.Link>
                </Nav.Item>
            </Nav>
            <SubView
                subView={subView}
                setSubView={setSubView}
                showAlert={showAlert}
                showDismissibleAlert={showDismissibleAlert}
            />
        </Container>
    )
}

export default FeedbackView
