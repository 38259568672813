import axiosInstance from './axios'

let baseUrl = 'api/comments/'

const getAll = (orderBy) => {
    const request = axiosInstance.get(baseUrl + '?order_by=' + orderBy)
    return request.then(response => response.data)
}

const createComment = (newComment) => { 
    const request = axiosInstance.post(baseUrl, newComment)
    return request.then(response => response.data)
}

const updateComment = (commentId, newComment) => {
    const request = axiosInstance.put(baseUrl + commentId + '/', newComment)
    return request.then(response => response)
}

const deleteComment = (commentId) => {
    const request = axiosInstance.delete(baseUrl + commentId)
    return request.then(response => response)
}

const functions = {
    getAll,
    createComment,
    updateComment,
    deleteComment
  }

export default functions