import { Container, Form } from 'react-bootstrap'
import Select from 'react-select'
import PropTypes from 'prop-types'

/**
 * A component for selecting comments using multiple-choice dropdown menu.
 * 
 * Renders a multiple-choice react-select component with comments from the
 * database as options. There is also an informational text hinting at where
 * the comment objects can be created and a link to that subview.
 */
const CommentSelector = ({
    comments,
    toCommentSubView,
    blockComments,
    handleBlockCommentsChange
}) => {
    const commentOptions = comments.map(comment => ({
        value: comment.id,
        label: comment.text.substring(0,50) + '...'
    }))

    return (
        <Container className="my-4 px-0">
            <Form.Label>Block comments</Form.Label>
            <Select
                value={blockComments}
                isMulti={true}
                closeMenuOnSelect={false}
                name="comments"
                options={commentOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleBlockCommentsChange}
            />
            <p>Comments can be created and edited on <a className="inText" onClick={toCommentSubView} href="#comments">comments tab</a>.</p>
        </Container>
    )
}

CommentSelector.propTypes = {
    /** Array of comment objects fetched from the database. */
    comments: PropTypes.arrayOf(PropTypes.object),

    /**
     * Function for switchin the subview to CommentSubView used in a link on
     * the form.
     */
    toCommentSubView: PropTypes.func,

    /** 
     * Hook for the array of comments selected using the form (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     * 
     * Prop is used in a react-select component and therefore objects in the
     * array must be in a format supported by it ({value: comment.id, label:
     * comment.category_name}). <strong>Notice that
     * this is not the same format as is used in the <i>comments</i> array.
     * </strong>
     */
    blockComments: PropTypes.arrayOf(PropTypes.object),

    /**
     * Handler function for the react-select component which uses
     * <i>blockComments</i> to store the selected comments (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleBlockCommentsChange: PropTypes.func
}

export default CommentSelector