import { Container } from 'react-bootstrap'

/**
 * A component for setting form button rows unified margin and padding.
 * 
 * Surrounds child elements in a react-bootstrap container with inline margin
 * and padding settings. Container is also given css class
 * <i>rdm-form-button-row</i>.
 * 
 * @example ./Examples/FormButtonRow.md
 */
const FormButtonRow = (props) => (
    <Container className="rdm-form-button-row px-0 mt-3 mb-4">
        {props.children}
    </Container>
)

export default FormButtonRow