export const questionAnswerCodes = `Write answers in rules in form`
                                    + ` <QUESTION-ID>:<ANSWERCODE>,`
                                    + ` e.g. DATA-1:A.`

export const questionRuleSpecific = `To refer to the answers of the question`
                                    + ` being created, use only answer code, e.g. A.`

export const ruleLogic = `Use correct logical syntax - accepted operators are AND,`
                        + ` OR, NOT. Parentheses are possible, and they can be nested.`
                        + ` Rule can be empty, in which case it is always true.`
                        + `\nYou can write e.g.`
                        + `\nDATA-1:A OR NOT (TEST-1:A AND TEST-2:B)`
                        + `\nor`
                        + `\n((DATA-1:A OR TEST-1:B) AND (NOT DATA-2:B OR DATA-2:C))`

export const nextQuestionsInstructions = `Write next questions using question identifiers,`
                                        + ` e.g. DATA-1.`
                                        + `\nTo create a new question, write`
                                        + ` <CATEGORY>-NEW, e.g. DATA-NEW.`
