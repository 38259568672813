import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Container, Form, Col, Row, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import axiosInstance from '../services/axios'
import '../styles.css'

/**
 * The view for admin login.
 * 
 * Admin user authentication and authorization are managed in separate Admin site 
 * (Admins link in site navigation). 
 * Each admin user can manage their own password on Profile page (handled by ProfileView..js) 
 */
const LogInView = ({ setAuth }) => {
    const [alert, setAlert] = useState({message:'', variant:'', visible:false, dismissible:false})
	const initialFormData = Object.freeze({
		username: '',
		password: '',
	});

    const navigate = useNavigate()

	const [formData, updateFormData] = useState(initialFormData)

    // Handler for form field content changes
	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		})
	}

    // Logging in
	const handleSubmit = (e) => {
		e.preventDefault();
        window.localStorage.setItem('user', formData.username)

		axiosInstance
			.post(`api/token/`, {
				username: formData.username,
				password: formData.password,
			})
			.then((res) => {
				window.localStorage.setItem('access_token', res.data.access)
				window.localStorage.setItem('refresh_token', res.data.refresh)
				axiosInstance.defaults.headers['Authorization'] =
					'Bearer ' + localStorage.getItem('access_token')
                setAuth(true)
				navigate("/")
			})
            .catch(error => {
                let message = error.response.data.detail || 'Login failed'
                showDismissibleAlert(message, 'danger')
            })
	}

    // Alerts
    const showDismissibleAlert = (alertMessage, alertVariant) => {
        setAlert({message: alertMessage, variant: alertVariant, visible: true, dismissible: true})
    }

    const closeDismissibleAlert = () => {
        setAlert({message: '', variant: '', visible: false, dismissible: false})
    }

	return (
        <Container>
            <div>
            <Alert variant={alert.variant} show={alert.visible} dismissible={alert.dismissible} onClose={closeDismissibleAlert}> {alert.message}</Alert>
                <h1>Login</h1>
            </div>
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formUsername">
                    <Row className='admin name'>
                        <Col md={{span: 2, offset: 1 }}>
                            User name
                        </Col>
                        <Col sm='3'>
                            <Form.Control
                                required
                                name='username'
                                type='text'
                                value={formData.username}
                                autoComplete="username"
                                onChange={(e) => handleChange(e)}
                                placeholder='name'
                            />
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPassword">
                    <Row>
                        <Col md={{span: 2, offset: 1 }}>
                        Password
                        </Col>
                        <Col sm='3'>
                            <input
                                className="form-control"
                                name='password'
                                type="password"
                                placeholder='password'
                                autoComplete="current-password"
                                value ={formData.password}
                                onChange={(e) => handleChange(e)}/>
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Row>
                        <Col md={{span: 2, offset: 1 }}>
                        </Col>
                        <Col sm='3'>
                            <Button className='btn-icon' onClick={(e) => handleSubmit(e)}>
                                    Login
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Container>
	)
}

LogInView.propTypes = {
    /**
     * Function to handle the state of user authentication. 
     * 
     * The checking of the state is handled in App.js. 
     */
    setAuth: PropTypes.func
}

export default LogInView
