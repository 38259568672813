import { useState } from 'react'
import { Button, Col, Form, Row, Collapse } from 'react-bootstrap'
import PropTypes from 'prop-types'
import CommentSelector from './CommentSelector'
import LinkSelector from './LinkSelector'
import FormButtonRow from '../FormButtonRow'
import { questionAnswerCodes, ruleLogic } from '../../common/ruleInstructions'

/**
 * A component for editing feedback blocks on RDM Intro feedback view.
 * 
 * Renders a form for editing feedback blocks. Form is implemented as a
 * <a href="https://reactjs.org/docs/forms.html#controlled-components">
 * controlled component</a> and it requires hooks for the data on the form
 * inputs and handler functions for handling changes to these fields as props.
 * Editor title text and submit button are also passed as props. Arrays of
 * comments and links found from the database are also needed so that the
 * user is able to add comments and links to a block from those in the
 * database. Props <i>toCommentSubView</i> and <i>toLinkSubView</i> are
 * functions coming from the <i>SubView</i> component of the
 * <i>FeedBackView</i> and are used for switching subviews from the form links.
 */
const BlockEditor = ({
    blockName,
    blockRule,
    blockComments,
    blockLinks,
    blockEditorHeading,
    blockEditorSubmitText,
    handleNameChange,
    handleRuleChange,
    handleBlockCommentsChange,
    handleBlockLinksChange,
    handleSave,
    handleClear,
    comments,
    toCommentSubView,
    links,
    toLinkSubView
}) => {
    const [openInstructions, setOpenInstructions] = useState(false)
    return (
        <Form>
            <h3>{blockEditorHeading}</h3>
            <Form.Group as={Row} controlId="blockName" className="my-1">
                <Form.Label column sm="4">Block name</Form.Label>
                <Col sm="8">
                    <Form.Control
                        as="input"
                        type="text"
                        value={blockName}
                        onChange={handleNameChange}
                    />
                </Col>
            </Form.Group> 
            <Form.Group as={Row} controlId='blockRule' className="my-1">
                <Form.Label column sm="4">Rule for showing block</Form.Label>
                <Col sm="8">
                    <Form.Control
                        as="input"
                        type="text"
                        value={blockRule}
                        onChange={handleRuleChange}
                    />
                </Col>
            </Form.Group>
            <div>
                <Button
                    className='btn-outline-primary'
                    style={{ borderColor: '#fff', padding: '0em' }}
                    size='sm'
                    onClick={() => setOpenInstructions(!openInstructions)}>
                    {openInstructions ? 'Hide rule instructions' : 'Open rule instructions'}
                </Button>
            </div>
            <Collapse in={openInstructions}>
                <Form.Text id='ruleHelpBlock' muted>
                    <Row className="py-0">
                        <Col style={{ whiteSpace: 'pre-line' }} sm='10'>
                            <p>{questionAnswerCodes}</p>
                            <p>{ruleLogic}</p>
                        </Col>
                    </Row>
                </Form.Text>
            </Collapse>
            <CommentSelector
                comments={comments}
                toCommentSubView={toCommentSubView}
                blockComments={blockComments}
                handleBlockCommentsChange={handleBlockCommentsChange}
            />
            <LinkSelector
                links={links}
                toLinkSubView={toLinkSubView}
                blockLinks={blockLinks}
                handleBlockLinksChange={handleBlockLinksChange}
            />
            <FormButtonRow>
                <Button
                    aria-label="Save new feedback block"
                    onClick={handleSave}
                >{blockEditorSubmitText}</Button>
                <Button
                    aria-label="Clear feedback block editing form"
                    onClick={handleClear}
                    className="btn-outline-primary"
                >Clear form</Button>
            </FormButtonRow>
        </Form>
    )
}

BlockEditor.propTypes = {
    /**
     * Hook for the block name input contents (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    blockName: PropTypes.string,

    /**
     * Hook for the rule input contents (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
    * controlled component</a>).
     */
    blockRule: PropTypes.string,

    /** 
     * Hook for the array of comments selected using the form (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     * 
     * Prop is passed to <i>CommentSelector</i> and from there to a
     * react-select component. Therefore the objects in the array must be in
     * a format accepted by react-select. <strong>Notice that this is not
     * the same format as is used in the <i>comments</i> array.</strong>
     */
    blockComments: PropTypes.arrayOf(PropTypes.object),

    /**
     * Hook for the array of links selected using the form (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     * 
     * Prop is passed to <i>LinkSelector</i> and from there to a react-select
     * component. Therefore the objects in the array must be in a format
     * accepted by react-select. <strong>Notice that this is not the same
     * format as is used in the <i>Links</i> array.</strong>
    */
    blockLinks: PropTypes.arrayOf(PropTypes.object),

    /** Title text of the editor. */
    blockEditorHeading: PropTypes.string,

    /** Submit button text. */
    blockEditorSubmitText: PropTypes.string,

    /**
     * Handler function for the block name input (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleNameChange: PropTypes.func,

    /**
     * Handler function for the rule input (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleRuleChange: PropTypes.func,

    /**
     * Handler function for the comment selector which uses
     * <i>blockComments</i> to store the selected comments (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleBlockCommentsChange: PropTypes.func,

    /**
     * Handler function for the link selector which uses
     * <i>blockLinks</i> to store the selected comments (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
    * controlled component</a>).
     */
    handleBlockLinksChange: PropTypes.func,

    /** Handler function for the form submit button. */
    handleSave: PropTypes.func,

    /** Handler function for the clear form button. */
    handleClear: PropTypes.func,

    /** Comment objects fetched from the database. */
    comments: PropTypes.arrayOf(PropTypes.object),

    /** Function for changing subview to <i>CommentSubview</i>. */
    toCommentSubView: PropTypes.func,

    /** Link objects fetched from the database. */
    links: PropTypes.arrayOf(PropTypes.object),

    /** Function used for changing subview to <i>LinkSubview</i>. */
    toLinkSubView: PropTypes.func
}

export default BlockEditor
