import React from 'react'
import { Table, Button } from 'react-bootstrap'
import { Pencil, Trash3 } from 'react-bootstrap-icons'
import PropTypes from 'prop-types'

/**
 * A component for listing survey questions. 
 *
 * Renders a list of questions and provides reading, updating and deletion functions to them.
 * Details of a single question may be read and updated via selecting it in this list. 
 * The selection of a question makes its details visible on the separate QuestionForm.js component.
 * 
 * Questions.js component is used by the QuestionView.js in folder 'src/views'.
 * The view shows both QuestionForm and Questions components, and serves their 
 * functionalities (creating, reading, updating, deleting) to backend and database. 
 */
 const Questions = ({ questions, deleteFunction, selectFunction }) => {

    const questionsSorted = [...questions].sort((a, b) => a.identifier > b.identifier ? 1 : -1);

    return (
        <div>
            <Table borderless className="my-1" size='sm'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Question</th>
                </tr>
            </thead>
            <tbody>
                {questionsSorted.map((question) => (
                <tr key={question.id}>
                    <td>{question.identifier}</td>
                    <td>{question.question_text}</td>
                    <td>
                        <Button 
                            variant='btn btn-icon'
                            aria-label="Edit question"
                            onClick={() => selectFunction({question}) }>
                            <Pencil/>
                        </Button>
                    </td>
                    <td>
                        <Button
                            variant='btn-icon btn-remove'
                            aria-label="Delete question" 
                            onClick={() => deleteFunction({question}) }>
                            <Trash3/>
                        </Button>
                    </td>
                </tr>
              ))}
            </tbody>
            
            </Table>
        </div>
    )
}


Questions.propTypes = {
    
    /**
     * A function for deleting a question. 
     * The functioning is defined in QuestionView.js.
     */
    deleteFunction: PropTypes.func,

    /**
     * A set of question items. 
     * The set is fetched from the database in QuestionView.js. 
     */
    questions: PropTypes.array,

    /**
     * A function for selecting a question to be viewed or edited. 
     * The functioning is defined in QuestionView.js.
     */
    selectFunction: PropTypes.func
}

export default Questions