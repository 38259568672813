import { useRef } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print'
import PropTypes from 'prop-types'

// Renders a comment.
const Comment = ({ comment, index }) => (
    <Container id={'comment-' + index} className="rdm-feedback-comment">
        <p>{comment.text}</p>
    </Container>
)

// Renders a link.
const Link = ({ link, index }) => (
    <Container id={'link-' + index} className="rdm-feedback-link">
        <p><a href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a></p>
    </Container>
)

// Renders a block (consists of comments and links).
const Block = ({ block, index }) => (
    <Container
        id={'block-' + index}
        className="rdm-feedback-block my-4"
    >
        <h3>{block.name}</h3>
        <Container className="rdm-feedback-comment-container">
            {block.comments.map((comment, index) => (
                <Comment
                    key={comment.id}
                    comment={comment}
                    index={index}
                />)
            )}
        </Container>
        <Container className="rdm-feedback-link-container">
            {block.links.map((link, index) => (
                <Link
                    key={link.id}
                    link={link}
                    index={index}
                />)
            )}
        </Container>
    </Container>
)

// Inline style string given to FeedbackList container div element.
const styleStr = `\
@page {margin: 15mm 15mm 15mm 20mm !important;}
@media print {
    a {text-decoration: underline;}
    a:after {
        content: " (" attr(href) ") ";
        font-size: 80%;
    }
}`

/**
 * A component for rendering the feedback generated for the user after he/she
 * has submitted her/his answers.
 * 
 * Component renders a list of feedback blocks which each can contain some
 * amount of comments and links. Component requires an array of blocks as
 * props. Component also renders a button that can be clicked to print the
 * feedback contents.
 */
const FeedbackList = ({ blocks }) => {
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
        documentTitle: 'RDM Intro feedback',
        onAfterPrint: () => alert('Done with printing!'),
        onPrintError: () => alert('Error: answers not printed!')
    })
    return (
        <div>
            <Container ref={componentRef}>
                <style>{styleStr}</style>
                <h1>Feedback based on your answers</h1>
                {blocks.map((block, index) => (
                    <Block
                        key={block.id}
                        block={block}
                        index={index}
                    />)
                )}
            </Container>
            <Button onClick={handlePrint}>Download or print PDF</Button>
        </div>
    )
}

FeedbackList.propTypes = {
    /** Feedback block objects fetched from the database. */
    blocks: PropTypes.arrayOf(PropTypes.object)
}

export { FeedbackList, Block, Link, Comment }
