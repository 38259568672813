import { Container, Form } from 'react-bootstrap'
import Select from 'react-select'
import PropTypes from 'prop-types'

/**
 * A component for selecting link using multiple-choice dropdown menu.
 * 
 * Renders a multiple-choice react-select component with links from the
 * database as options. There is also an informational text hinting at where
 * the link objects can be created and a link to that subview.
 */
const LinkSelector = ({
    links,
    toLinkSubView,
    blockLinks,
    handleBlockLinksChange
}) => {
    const linkOptions = links.map(link => ({
        value: link.id,
        label: link.text.substring(0,50) + '...'
    }))

    return (
        <Container className="my-4 px-0">
            <Form.Label>Block links</Form.Label>
            <Select
                value={blockLinks}
                isMulti
                closeMenuOnSelect={false}
                name="links"
                options={linkOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleBlockLinksChange}
            />
            <p>Links can be created and edited on <a className="inText" onClick={toLinkSubView} href="#links">links tab</a>.</p>
        </Container>
    )
}

LinkSelector.propTypes = {
    /** Array of link objects fetched from the database. */
    links: PropTypes.arrayOf(PropTypes.object),

    /**
     * Function for switchin the subview to LinkSubView used in a link on
     * the form.
     */
    toLinkSubView: PropTypes.func,

    /** 
     * Hook for the array of links selected using the form (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     * 
     * Prop is used in a react-select component and therefore objects in the
     * array must be in a format supported by it ({value: link.id, label:
     * link.category_name}). <strong>Notice that
     * this is not the same format as is used in the <i>links</i> array.
     * </strong>
     */
    blockLinks: PropTypes.arrayOf(PropTypes.object),

    /**
     * Handler function for the react-select component which uses
     * <i>blockLinks</i> to store the selected links (see
     * <a href="https://reactjs.org/docs/forms.html#controlled-components">
     * controlled component</a>).
     */
    handleBlockLinksChange: PropTypes.func
}

export default LinkSelector