import { useState, useEffect } from 'react'
import QuestionCard from '../components/User/QuestionCard'
import { FeedbackList } from '../components/Feedback/FeedbackList'
import Notification from '../components/User/Notification'
import questionService from '../services/questions'
import userAnswersService from '../services/userAnswers'
import settingsService from '../services/settings'
import ruleChecker from '../logic/RuleChecker'
import { Button, Spinner } from 'react-bootstrap'
import ConfirmationDialog from '../components/ConfirmDialog'

const Start = ({ title, text, handleStart }) => {
    return (
        <div>
            <h2>{ title }</h2>
            <div style={{ whiteSpace: 'pre-line',
                            textAlign: 'justify',
                            marginTop: '2em',
                            marginBottom: '2em' }}>
                { text }
            </div>
            <Button variant='primary' onClick={handleStart}>Start</Button>
        </div>
    )
}

const Error = () => {
    return (
        <div>
            <p>Something went wrong.</p>
            <p>Wait a moment or try refreshing the page.</p>
        </div>
    )
}

const Finish = ({ handleSubmit }) => {
    return (
        <div>
            <h3>Finished!</h3>
            <div>
                <p>Submit your answers to get feedback.</p>
                <form onSubmit={handleSubmit}>
                    <Button type='submit'>Submit answers</Button>
                </form>
            </div>
        </div>
    )
}

const Feedback = ({ feedback, handleExit }) => {
    return (
        <div>
            {!feedback || feedback.length === 0
            ? <p>No feedback to show</p>
            : <FeedbackList blocks={feedback} />}
            <br></br>
            <Button className="btn-danger" onClick={handleExit} >Exit</Button>
        </div>
    )
}

/**
 * The page or view that is visible for the regular user and where the user can
 * answer the survey.
 * 
 * The component has four child components corresponding to the different stages
 * of the survey: Start, QuestionCard, Finish and Feedback.
 * 
 * The Start component is shown when first coming to the page - it will render the
 * landing page title and text, and 'Start' button.
 * 
 * QuestionCard component will show the currentQuestion and answer options to the user.
 * 
 * When there are no more questions in the questionQueue, the Finish component is shown,
 * where the user can submit their answers.
 * 
 * After submitting the answers, the Feedback component is shown - it will use FeedbackList
 * component to render the feedback received from the backend, or show text 'No Feedback'
 * if there is no feedback.
 * 
 * If the conditions for showing any of these components are not met, the Error
 * component is showed instead.
 * 
 */
const UserView = () => {

    const [pageTitle, setPageTitle] = useState('')
    const [pageText, setPageText] = useState('')
    const [questions, setQuestions] = useState(null)
    const [notification, setNotification] = useState('')
    const [settings, setSettings] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState({ message: '', visible: false })
    const [quizState, setQuizState] = useState({
        currentQuestion: null,
        questionQueue: [],
        userAnswers: [],
        startTime: '',
        finished: false,
        submitted: false,
        feedback: null
    })

    useEffect(() => {
        setIsLoading(true)

        questionService
            .getAll()
            .then(questions => {
                setQuestions(questions)
            })

        settingsService
            .getAll()
            .then(settings => {
                const titleSetting = settings.filter(
                    setting => setting.setting_name === "landing_page_title"
                )[0]
                const textSetting = settings.filter(
                    setting => setting.setting_name === "landing_page_text"
                )[0]

                titleSetting && titleSetting.setting_value.length > 0
                ? setPageTitle(titleSetting.setting_value)
                : setPageTitle('No title')
                
                textSetting && textSetting.setting_value.length > 0
                ? setPageText(textSetting.setting_value)
                : setPageText('No text')
                
                setSettings(settings)
                setIsLoading(false)
            })
            .catch(error => {
                setPageTitle('Unable to load resources.')
                setIsLoading(false)
            })
    }, [])

    const getCurrentTime = () => {
        const date = new Date()
        const timestamp = date.getTime() / 1000
        return timestamp
    }

    const showNotification = ({ message }) => {
        setNotification(message)
        const timer = setTimeout(() => {
            setNotification('')
        }, 3000)
        return timer
    }

    const showSpinner = () => {
        return (
            <div>
                <p>Loading, please wait...</p>
                <Spinner animation='border' variant='dark' />
            </div>
        )
    }

    // pressing the start button
    const handleStart = () => {
        if (!questions || !settings) {
            const message = 'Loading questions and settings, please click Start again.'
            showNotification({ message })
            return
        }

        if (questions.length === 0) {
            const message = 'There are no questions.'
            showNotification({ message })
            return
        }

        const settingForStartingQuestion = settings.filter(
            setting => setting.setting_name === 'starting_question'
        )[0]
        if (!settingForStartingQuestion) {
            setNotification('ERROR: Starting question is not set!')
            return
        }

        const startingQuestion = questions.filter(
            q => q.id === parseInt(settingForStartingQuestion.setting_value)
        )[0]
        if (!startingQuestion) {
            setNotification('ERROR: Starting question does not exist!')
            return
        }

        setNotification('')
        setQuizState({
            currentQuestion: startingQuestion,
            questionQueue: [],
            userAnswers: [],
            startTime: getCurrentTime(),
            finished: false,
            submitted: false,
            feedback: null
        })
    }

    // submit all answers to the server
    const handleSubmit = (event) => {
        event.preventDefault()
        const data = { start_time: quizState.startTime,
                        end_time: getCurrentTime(),
                        answers: quizState.userAnswers }
        setIsLoading(true)
        userAnswersService
            .postAnswers(data).then(response => {
                setQuizState({
                    ...quizState,
                    submitted: true,
                    feedback: response
                })
                const message = 'Answers submitted.'
                showNotification({ message })
                setIsLoading(false)
            })
            .catch(error => {
                const message = 'ERROR: Unable to submit answers.'
                showNotification({ message })
                setIsLoading(false)
            })
    }

    const handleQuestionChange = (answers) => {
        // check that user answered something - unnecessary, but just in case
        if (answers.length === 0) {
            let message = 'You have to select some answer.'
            showNotification(message)
            return
        }
        let choices = answers.map(a => quizState.currentQuestion.id + ':' + a)
        let allAnswers = quizState.userAnswers.concat(choices)
        let updatedQuestionQueue = addNextQuestionsToQueue(allAnswers)
        let newCurrentQuestion = updatedQuestionQueue.shift()
        setQuizState({
            ...quizState,
            currentQuestion: newCurrentQuestion,
            userAnswers: allAnswers,
            questionQueue: updatedQuestionQueue,
            finished: !newCurrentQuestion
        })
    }

    const addNextQuestionsToQueue = (allAnswers) => {
        let rules = quizState
                        .currentQuestion
                        .rules
                        .sort((r1, r2) => r1.position - r2.position)
        let newQueue = [...quizState.questionQueue]
        let ids = newQueue.map(question => question.id)

        for (let i = 0; i < rules.length; i++) {
            if (ruleChecker.check(rules[i].question_rule, allAnswers)) {
                let questionIds = rules[i].next_questions.split(',')
                questionIds = questionIds.filter(id => !ids.includes(parseInt(id)))
                questionIds = questionIds.filter(id => !quizState.userAnswers
                                                        .map(answer => answer.split(':')[0])
                                                        .includes(id))

                if (questionIds.length > 0) {
                    let nextQuestions = questions.filter(
                        question => questionIds.includes(question.id.toString())
                    )
                    newQueue = newQueue.concat(nextQuestions)
                }
                // if question is hierarchical, other rules are not checked
                if (quizState.currentQuestion.hierarchical) {
                    break
                }
            }
        }
        return newQueue
    }

    // passed to Feedback component, which has the exit button
    // pressing the button sets confirmDialog visible
    const handleExit = () => {
        const message = 'Are you sure you want to leave?'
                        + ' All history will be erased and'
                        + ' you will not be able to see your'
                        + ' feedback anymore.'
        setConfirmDialog({ message: message, visible: true })
    }

    // passed to confirmationDialog
    // pressing 'Yes' will call this with value true, and 'No' with false
    const confirmExit = (confirmation) => {
        if (confirmation) {
            setQuizState({
                currentQuestion: null,
                questionQueue: [],
                userAnswers: [],
                startTime: '',
                finished: false,
                submitted: false,
                feedback: null
            })
        }
        setConfirmDialog({ message: '', visible: false })
    }

    // show Start, QuestionCard, Finish or Feedback
    const showView = () => {
        if (quizState.startTime === '') {
            return <Start title={pageTitle} text={pageText} handleStart={handleStart} />
        } else if (quizState.finished && !quizState.submitted) {
            return <Finish handleSubmit={handleSubmit} />
        } else if (quizState.submitted) {
            return <Feedback feedback={quizState.feedback} handleExit={handleExit} />
        } else if (quizState.currentQuestion) {
            return <QuestionCard question={quizState.currentQuestion}
                getNextQuestion={handleQuestionChange} />
        }

        return <Error />
    }

    return (
        <div>
            <Notification message={notification} />
            <br></br>
            <div>
                {isLoading ? showSpinner() : showView()}
            </div>
            {confirmDialog.visible && 
            <ConfirmationDialog setDialog={confirmExit} message={confirmDialog.message} />}
        </div>
    )
}

export default UserView
