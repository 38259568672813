/**
 * The function that checks if a given rule contains syntax errors.
 * Returns a list of found errors. Used in QuestionForm component.
 *
 * @param {string} rule the rule that is checked
 * @public
 */
const spellCheck = (rule) => {
    let errorMessages = []

    if (!rule) {
        return errorMessages
    }

    const ruleArray = getRuleArray(rule)

    if (!onlyAllowedCharacters(rule)) {
        errorMessages = errorMessages.concat(
            'Must contain only allowed characters: letters, digits, spaces, ( ) : -'
        )
    }

    if (!validParentheses(ruleArray)) {
        errorMessages = errorMessages.concat('Invalid parentheses.')
    }

    const invalidOperators = incorrectOperators(ruleArray)
    if (invalidOperators) {
        const message = 'Invalid value or operator: ' + invalidOperators
        errorMessages = errorMessages.concat(message)
    }

    const syntaxErrors = incorrectSyntax(ruleArray)
    if (syntaxErrors.length > 0) {
        errorMessages = errorMessages.concat('Syntax errors: ')
        errorMessages = errorMessages.concat(syntaxErrors)
    }

    return errorMessages;
}

// turn string into an array,
// e.g. 'TEST-1:A OR TEST-2:A' => ['TEST-1:A', 'OR', 'TEST-2:A']
const getRuleArray = (rule) => {
    let r = rule.split('(').join(' ( ')
    r = r.split(')').join(' ) ')
    const ruleArray = r.split(' ').filter(r => r)
    return ruleArray
}

// only contains allowed characters
const onlyAllowedCharacters = (rule) => {
    const regex = /^[\w\s():-]*$/
    return regex.test(rule)
}

// parentheses are set right
const validParentheses = (rule) => {
    let count = 0
    for (let i = 0; i < rule.length; i++) {
        if (count < 0) {
            return false
        }
        if (rule[i] === '(') {
            count++
            continue
        }
        if (rule[i] === ')') {
            count--
            continue
        }
    }
    return count === 0
}

// returns list of unrecognized elements that are not accepted logical
// operators or correctly formatted question:answer variables
const incorrectOperators = (rule) => {
    const operators = ['AND', 'OR', 'NOT']
    let errors = []
    const regexQuestionAnswer = /^[\w]+-[\d]+:[a-zA-Z]$/
    const regexAnswer = /^[a-zA-Z]$/
    for (let i = 0; i < rule.length; i++) {
        if (rule[i] === '(' || rule[i] === ')') {
            continue
        }
        if (!operators.includes(rule[i])) {
            if (!(regexQuestionAnswer.test(rule[i]) || regexAnswer.test(rule[i]))) {
                errors = errors.concat(rule[i])
            }
        }
    }
    return errors.join(', ')
}

// checks the expression syntax and returns a list of found errors
const incorrectSyntax = (rule) => {
    const operators = ['AND', 'OR', 'NOT']
    let errors = []
    for (let i = 0; i < rule.length; i++) {
        if (i === 0) {
            if (rule[i] === 'AND' || rule[i] === 'OR') {
                errors = errors.concat('cannot start sentence with AND or OR')
            } else if (rule[i] === '(' && rule[i + 1] === ')') {
                errors = errors.concat('empty parentheses')
            }
        } else if (i === rule.length - 1) {
            if (operators.includes(rule[i])) {
                errors = errors.concat('cannot end sentence with logical operator')
            }
        } else {
            if (rule[i] === '(') {
                if (rule[i + 1] === 'AND' || rule[i + 1] === 'OR') {
                    errors = errors.concat('cannot start subsentence with AND or OR')
                } else if (rule[i + 1] === ')') {
                    errors = errors.concat('empty parentheses')
                }
            } else if (rule[i] === ')') {
                if (!operators.includes(rule[i + 1]) && rule[i + 1] !== ')') {
                    errors = errors.concat(
                        'closing bracket must be followed by a logical operator'
                        + ' or another closing bracket'
                    )
                }
            } else if (operators.includes(rule[i])) {
                if (rule[i + 1] === ')') {
                    errors = errors.concat(
                        'cannot end subsentence with a logical operator'
                    )
                } else if (rule[i] === 'NOT') {
                    if (operators.includes(rule[i + 1])) {
                        errors = errors.concat('cannot have logical operator after NOT')
                    }
                } else {
                    if (operators.includes(rule[i + 1]) && rule[i + 1] !== 'NOT') {
                        errors = errors.concat(
                            'cannot have two consecutive logical operators unless'
                            + ' second one is NOT'
                        )
                    }
                }
            } else if (rule[i].includes(':')) {
                if (!operators.includes(rule[i + 1]) && rule[i + 1] !== ')') {
                    errors = errors.concat(
                        'question:answer choice must be followed by a logical'
                        + ' operator or a closing bracket')
                }
            }
        }
    }
    return errors
}

const functions = {
    spellCheck,
    getRuleArray,
    onlyAllowedCharacters,
    validParentheses,
    incorrectOperators,
    incorrectSyntax
}

export default functions
