import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { Pencil, Trash3 } from 'react-bootstrap-icons'
import PropTypes from 'prop-types'
import truncateString from '../common/truncateString'

// Renders an individual list button.
const ListButton = ({ button, handlers, ids, btnIndex, rowIndex }) => {
    switch(button) {
        case 'delete':
            return (
                <Button
                    variant="btn-icon btn-remove"
                    className="btn-sm my-0"
                    aria-label={'Delete item number ' + (rowIndex + 1)}
                    type="button"
                    value={ids[rowIndex]}
                    onClick={handlers[btnIndex]}
                ><Trash3 /></Button>
            )
        case 'edit':
            return (
                <Button
                    variant="btn btn-icon"
                    className="btn-sm my-0"
                    aria-label={'Edit item number ' + (rowIndex + 1)}
                    type="button"
                    value={ids[rowIndex]}
                    onClick={handlers[btnIndex]}
                ><Pencil /></Button>
            )
        default:
            return null
    }
}

// Renders buttons for a list row.
const Buttons = ({ buttons, handlers, ids, rowIndex }) => (
    buttons.map((button, btnIndex) =>
        <ListButton
            key={'b' + ids[rowIndex] + btnIndex}
            button={button}
            handlers={handlers}
            ids={ids}
            btnIndex={btnIndex}
            rowIndex={rowIndex}
        />
    )
)

// Renders individual data field as a td element.
const FieldItem = ({ field, chars }) => (
    <td>{truncateString(field, chars)}</td>
)

// Renders data fields (columns) for a list row.
const Fields = ({ fields, colChars, id, index }) => (
    fields.map((field, i) =>
        <FieldItem
            key={'f' + id + i}
            field={field[index]}
            chars={colChars[i]}
        />
    )
)

// Renders an individiual list row.
const ListRowItem = ({ ids, fields, buttons, colChars, handlers, id, index }) => (
    <tr>
        <Fields
            key={'f' + id}
            fields={fields}
            colChars={colChars}
            id={id}
            index={index}
        />
        <td className="py-1">
            <Buttons
                key={'b' + id}
                buttons={buttons}
                handlers={handlers}
                ids={ids}
                rowIndex={index}
            />
        </td>
    </tr>
)

// Renders list rows iteratively.
const ListRows = ({ ids, fields, buttons, colChars, handlers }) => (
    ids.map((id, index) =>
        <ListRowItem
            key={'r' + id}
            ids={ids}
            fields={fields}
            buttons={buttons}
            colChars={colChars}
            handlers={handlers}
            id={id}
            index={index}
        />
    )
)

// Renders individual th elements inside thead.
const HeaderItem = ({ text, sortHandler, index, numOfCols }) => {
    if (index === (numOfCols - 1)) {
        return (
            <th scope="col">
                {text}
            </th>    
        )
    }
    return (
        <th
            scope="col"
            onClick={sortHandler}
            value={index}
            className="rdm-list-head-item"
        >
            {text}
        </th>
    )
}

// Renders thead element for the table.
const ListHeader = ({ header, sortHandler, numOfCols }) => (
    <thead>
        <tr>
            {header.map((text, i) => 
                    <HeaderItem
                        key={i}
                        text={text}
                        sortHandler={sortHandler}
                        index={i}
                        numOfCols={numOfCols}
                    />
            )}
        </tr>
    </thead>
)

// Renders individual col definitions inside the colgroup.
const ColItem = ({ width, header, index }) => {
    let identifier = header[index] === ''
        ? 'empty'
        : header[index].toLowerCase()
    return (
        <col id={'rdm-col-' + identifier} style={{width: + width + '%'}} />
    )
}

// Renders a colgroup element for the table.
const ColGroup = ({ colWidths, header }) => (
    <colgroup>
        {colWidths.map((width, index) =>
            <ColItem
                key={index}
                width={width}
                header={header}
                index={index}
            />
        )}
    </colgroup>
)

// Checks props given to List component - returns true if everything is fine
// and false otherwise.
const checkProps = (
    ids,
    fields,
    buttons,
    colChars,
    colWidths,
    header,
    buttonHandlers,
    textIfEmpty,
    numOfCols
) => {
    if (ids === undefined
        || fields === undefined
        || buttons === undefined
        || buttonHandlers === undefined
    ) {
        return false
    } 
    fields.forEach((field, id) => {
        if (ids.length !== field.length) {
            return false
        }
    })
    if (colChars !== undefined && colChars.length !== fields.length) {
        return false
    }
    if (colWidths !== undefined && colWidths.length !== numOfCols) {
        return false
    }
    if (header !== undefined && header.length < (numOfCols - 1)) {
        return false
    }
    if (buttonHandlers.length !== buttons.length) {
        return false
    }
    if (textIfEmpty === undefined) {
        return false
    }
    return true
 }

 /**
  * A component for listing items such as comments, links, blocks or questions.
  * 
  * Renders a list of items as an html table that has as many text columns as
  * there are data arrays in the fields prop. A column for function buttons is
  * also added to the table. Buttons can be configured using <i>buttons</i> and
  * <i>buttonHandlers</i> props. Currently button types 'edit' and 'delete' are
  * recognized. Columns can be given widths as percents using <i>colWidths</i>
  * prop and the number of characters that is shown of the data field can be
  * set using <i>colChars</i> prop. It is also mandatory to give the list a
  * string that is shown if there is no data to render. This is done using
  * <i>textIfEmpty</i> prop.
  * 
  * Unique item identifiers (such as database ids) can be given using
  * <i>ids</i> prop. These ids are set as value property of the button elements
  * on the item row and can be used by the button handler to identify the item
  * it should affect.
  * 
  * List can be given a header by giving headings for each text column in
  * <i>header</i> prop. Button column does not get a heading. List can also be
  * given a handler, which sorts the items according to the column whose title
  * the user clicks on. This can be done using the <i>sortHandler</i> prop.
  */
const List = ({
    ids,
    fields,
    buttons,
    colChars,
    colWidths,
    header,
    sortHandler,
    buttonHandlers,
    textIfEmpty
}) => {
    const numOfCols = fields.length + 1

    const propsOk = checkProps(
        ids, fields, buttons, colChars, colWidths, header, buttonHandlers,
        textIfEmpty, numOfCols
    )
    if (propsOk === false) return null

    if (ids.length === 0) {
        const output = (textIfEmpty === undefined)
            ? 'List is empty.'
            : textIfEmpty
        return <p className="faded">{output}</p>
    }

    // Add an empty string to the end of header array for the button column.
    let _header = header
    if (header !== undefined && header.length < numOfCols) {
        _header = header.concat([''])
    }

    return (
        <Table borderless className="rdm-list my-1">
            <ColGroup
                colWidths={colWidths}
                header={_header}
            />
            {header !== undefined ?
                <ListHeader
                    header={_header}
                    sortHandler={sortHandler}
                    numOfCols={numOfCols}
                />
                : null
            }
            <tbody>
                <ListRows
                    ids={ids}
                    fields={fields}
                    buttons={buttons}
                    colChars={colChars}
                    handlers={buttonHandlers}
                />
            </tbody>
        </Table>
    )
}

List.propTypes = {
    /**
     * Array of unique item id's used as reference to these items (e.g. database
     * id's). Order of the array must be the same as in the fields arrays.
     * 
     * This prop is REQUIRED.
     */
    ids: PropTypes.array.isRequired,

    /**
     * Item data to be displayd on the list columns in an array of arrays. Each
     * array contains data for one column. Data must thus be arranged so that
     * data for each of the listed items have the same index on all of the
     * arrays.
     * 
     * This prop is REQUIRED.
     */
    fields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,

    /**
     * Buttons displayed in the rows of the list as an array of string
     * identifiers referring to specific buttons. Currently recognizes 'delete'
     * and 'edit' identifiers.
     * 
     * This prop is REQUIRED.
     */
    buttons: PropTypes.arrayOf(PropTypes.string).isRequired,

    /**
     * Number of characters to which column text is truncated. Give numbers in
     * an array in the order of the columns in the fields array.
     */
    colChars: PropTypes.arrayOf(PropTypes.number),

    /**
     * You can give widths to columns in percents by giving them in this array.
     * Order of the widths in the array must correspond to the order of columns.
     */
    colWidths: PropTypes.arrayOf(PropTypes.number),

    /**
     * A header can be added for the list by giving headings for each data
     * column in this array of strings. Button column does not get a heading.
     */
    header: PropTypes.arrayOf(PropTypes.string),

    /**
     * Handler function for sorting of the columns by clicking on column
     * headings.
     */
    sortHandler: PropTypes.func,

    /**
     * Handler functions for the list buttons. Functions must be given in the
     * array in the same order as buttons in their respective arrray.
     * 
     * This prop is REQUIRED.
     */
    buttonHandlers: PropTypes.arrayOf(PropTypes.func).isRequired,

    /**
     * String to be shown if the list does not have any content.
     * 
     * This prop is REQUIRED.
     */
    textIfEmpty: PropTypes.string.isRequired
}

export default List