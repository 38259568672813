import axiosInstance from './axios'

let baseUrl = 'api/blocks/'

const getAll = (orderBy) => {
    const request = axiosInstance.get(baseUrl + '?order_by=' + orderBy)
    return request.then(response => response.data)
}

const createBlock = (newBlock) => { 
    const request = axiosInstance.post(baseUrl, newBlock)
    return request.then(response => response.data)
}

const updateBlock = (blockId, newBlock) => {
    const request = axiosInstance.put(baseUrl + blockId + '/', newBlock)
    return request.then(response => response)
}

const deleteBlock = (blockId) => {
    const request = axiosInstance.delete(baseUrl + blockId)
    return request.then(response => response)
}

const functions = {
    getAll,
    createBlock,
    updateBlock,
    deleteBlock
  }

export default functions