import axiosInstance from './axios'

let baseUrl = 'api/user_answers/'

const postAnswers = async answers => {
    const response = await axiosInstance.post(baseUrl, answers)
    return response.data
}

const functions = {
    postAnswers
}

export default functions