/**
 * Truncates string 'text' to given length ('length').
 * 
 * @param {string} text 
 * @param {number} length 
 * @returns Truncated string.
 */
const truncateString = (text, length) => {
    if (text.length > length) {
        return text.substring(0, length) + '...'
    }
    return text
}

export default truncateString
