import axiosInstance from './axios'

let baseUrl = 'api/questions/'

const getQuestion = objectToGet => {
    const request = axiosInstance.get(baseUrl + objectToGet.id, objectToGet)
    return request.then(response => response.data)
}

const getAll = () => {
    const request = axiosInstance.get(baseUrl)
    return request.then(response => response.data)
}

const addNewQuestion = newObject => {
    console.log('addNewQuestion:', newObject)
    const request = axiosInstance.post(baseUrl, newObject)
    return request.then(response => response.data)
}

const deleteQuestion = objectToDelete => {
    const request = axiosInstance.delete(baseUrl + objectToDelete.id, objectToDelete)
    return request.then(response => response)
}

const updateQuestion = (objectToUpdate, newObject) => {
    const request = axiosInstance.put(baseUrl + objectToUpdate.id +'/', newObject)
    return request.then(response => response.data)
}

const functions = {
    getAll,
    addNewQuestion,
    deleteQuestion,
    getQuestion, 
    updateQuestion
  };

export default functions;